import 'url-search-params-polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './scenes/App/App';
import * as serviceWorker from './serviceWorker';
import moment from 'moment';
import 'moment/locale/fr';
import { ConfigProvider } from 'antd';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Invite from './scenes/BackofficeUsers/Invite';
import ResetPassword from './scenes/BackofficeUsers/ResetPassword';
import Map from './scenes/Map/Map';
import frFR from 'antd/lib/locale-provider/fr_FR';
import { SessionContextProvider } from './context/session-context';

moment.locale('fr');

const root = createRoot(document.getElementById('root'));

root.render(
  <SessionContextProvider>
    <ConfigProvider locale={frFR}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/reset-password/:token" element={<ResetPassword />} />
          <Route exact path="/invite/:email/:token" element={<Invite/>} />
          <Route exact path="/map" element={<Map />} />
          <Route path="*" element={<App />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  </SessionContextProvider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
