import React, { useContext } from 'react';
import { SessionContext } from '../../context/session-context';
import UserLink from '../User/UserLink';
import BackofficeUserRole from './BackofficeUserRole';
import { MobileOutlined } from '@ant-design/icons';
import { List } from 'antd';
import _ from 'lodash';
import styled from 'styled-components';
import SendInviteButton from './ReinviteBackofficeUserButton';
import { Link } from 'react-router-dom';

const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
`;
const UserLine = ({ user, currentUser }) => (
  <List.Item>
    <Link to={`/users/${user.id}`} style={{ display: 'block', flex: 1, color: 'inherit' }}>
      <LineContainer>
        <div style={{ fontWeight: 'bold' }}>
          <UserLink user={user} />
        </div>
        <div>
          <BackofficeUserRole userRole={user.role} />
        </div>
      </LineContainer>
      <LineContainer>
        <div style={{ wordBreak: 'break-all' }}>
          {user.email}
        </div>
        {currentUser.can_manage_users && user.invite_pending && (
          <div>
            <SendInviteButton user={user} />
          </div>
        )}
        {user.has_push_tokens && (
          <MobileOutlined />
        )}
      </LineContainer>
    </Link>
  </List.Item>
);

const BackofficeUsersList = ({ users }) => {
  const [session] = useContext(SessionContext);

  return (
    <div>
      <List
        dataSource={_.orderBy(users, ['role'], ['desc'])}
        renderItem={u => (<UserLine user={u} currentUser={session.user} />)}
        size="small"
      />
    </div>
  );
};

export default BackofficeUsersList;
