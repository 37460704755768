import React, { useState } from 'react';
import { Card, DatePicker, Row, Col } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

const ChartsOverviewLastYear = (props) => {
  const [state, setState] = useState({
    dailyStart: moment().subtract(2, 'months').startOf('day'),
    dailyEnd: moment(),
  });

  const dailyDisabledStartDate = (startValue) => {
    const endValue = state.dailyEnd;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.isAfter(endValue);
  };

  const dailyDisabledEndDate = (endValue) => {
    const startValue = state.dailyStart;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.isBefore(startValue);
  };

  const { component, data, series, title, loading } = props;
  const ChartComponent = component;

  const dailyDateSelect = (
    <div>
      <DatePicker
        style={{ width: 140 }}
        format="DD/MM/YYYY"
        value={state.dailyStart}
        onChange={(v) => setState({ ...state, dailyStart: v.startOf('day') })}
        disabledDate={dailyDisabledStartDate}
        allowClear={false}
      />
      <div style={{ display: 'inline-block', marginLeft: 8, marginRight: 8 }}>→</div>
      <DatePicker
        style={{ width: 140 }}
        format="DD/MM/YYYY"
        value={state.dailyEnd}
        onChange={(v) => setState({ ...state, dailyEnd: v.endOf('day') })}
        disabledDate={dailyDisabledEndDate}
        allowClear={false}
      />
    </div>
  );

  return (
    <div>
      <Row>
        <Col span={24}>
          <Card title="Jour par jour" extra={dailyDateSelect} style={{ marginBottom: 16 }}>
            <ChartComponent
              data={data}
              series={series}
              minDate={state.dailyStart}
              maxDate={state.dailyEnd}
              title={`${title} (par jour)`}
              loading={loading}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={{ xs: 0, md: 16 }}>
        <Col xs={24} md={12}>
          <Card title="Semaine par semaine" style={{ marginBottom: 16 }}>
            <ChartComponent
              data={data}
              series={series}
              minDate={moment().subtract(4, 'months').startOf('day').toISOString()}
              maxDate={moment()}
              aggBy="week"
              title={`${title} (par semaine)`}
              loading={loading}
            />
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card title="Mois par mois" style={{ marginBottom: 16 }}>
            <ChartComponent
              data={data}
              series={series}
              minDate={moment().subtract(1, 'year').startOf('day').toISOString()}
              maxDate={moment()}
              aggBy="month"
              title={`${title} (par mois)`}
              loading={loading}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

ChartsOverviewLastYear.propTypes = {
  data: PropTypes.array,
  series: PropTypes.array.isRequired,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

export default ChartsOverviewLastYear;
