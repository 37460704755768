import React, { useContext } from 'react';
import { SessionContext } from '../../context/session-context';
import UserLink from '../User/UserLink';
import BackofficeUserRole from './BackofficeUserRole';
import { MobileOutlined } from '@ant-design/icons';
import { Divider, Table, Tooltip } from 'antd';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import SendInviteButton from './ReinviteBackofficeUserButton';

const BackofficeUsersTable = ({ users }) => {
  const [session] = useContext(SessionContext);

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'name',
    },
    {
      title: 'Adresse email',
      dataIndex: 'email',
    },
    {
      title: 'Rôle',
      dataIndex: 'role',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
    },
  ];
  const proIcon = (
    <Tooltip title="Cet utilisateur reçoit des notifications sur NEOS Pro">
      <MobileOutlined />
    </Tooltip>
  );
  const data = _.orderBy(users, ['role'], ['desc']).map((u) => {
    return {
      id: u.id,
      key: u.id,
      name: (<>
        <UserLink user={u} />
        {u.has_push_tokens && (session.user.can_create_admins ? (
          <a
            href={`https://analytics.amplitude.com/alephom/project/222736/search/${u.id}`}
            target="_blank"
            title="Voir cet utilisateur sur Amplitude"
            rel="noopener noreferrer"
          >
            {proIcon}
          </a>
        ) : proIcon)}
      </>),
      role: (<BackofficeUserRole userRole={u.role} />),
      email: (<span>
        {u.email}
        {session.user.can_manage_users && u.invite_pending && (
          <div style={{ float: 'right' }}>
            <SendInviteButton user={u} />
          </div>
        )}
      </span>),
      actions: (
        <span>
          <Link to={`/users/${u.id}`} title="Accéder aux détails de cet utilisateur">
            Voir
          </Link>
          <Divider type="vertical" />
          <Link to={`/users/${u.id}/edit`} title="Modifier les droits de cet utilisateur">
            Modifier
          </Link>
        </span>
      ),
    };
  });

  return (
    <div>
      <Table
        bordered
        dataSource={data}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default BackofficeUsersTable;
