import React from 'react';
import UserLink from '../User/UserLink';
import { Divider, Table } from 'antd';
import _ from 'lodash';
import { Link } from 'react-router-dom';

export default ({ users }) => {
  const columns = [
    {
      title: 'Nom',
      dataIndex: 'name',
    },
    {
      title: 'Adresse email',
      dataIndex: 'email',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
    },
  ];

  const data = _.orderBy(users, ['family_name', 'given_name'], ['asc', 'asc']).map((u) => {
    return {
      id: u.id,
      key: u.id,
      name: (<UserLink user={u} />),
      email: u.email,
      actions: (
        <span>
          <Link to={`/queuebusting/users/${u.id}`} title="Accéder aux détails de cet utilisateur">
            Voir
          </Link>
          <Divider type="vertical" />
          <Link to={`/queuebusting/users/${u.id}/edit`} title="Modifier les droits de cet utilisateur">
            Modifier
          </Link>
        </span>
      ),
    };
  });

  return (
    <div>
      <Table
        bordered
        dataSource={data}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};
