import React, { useState } from 'react';
import { Button, Select, Form } from 'antd';
import BackofficeUserRole from './BackofficeUserRole';
import styled from 'styled-components';

const Table = styled.table`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.45);
  text-align: center;
  margin-bottom: 1rem;

  td, th, tr {
    border: 1px solid rgba(0, 0, 0, 0.45);
  }
`;
const Action = styled.td`
  text-align: left;
`;
const Td = styled.td`
  ${props => props.active ? 'color: black;' : ''}
`;
const Th = styled.th`
  ${props => props.active ? 'color: black;' : ''}
`;

const rights = [
  {
    description: 'Voir les achats',
    gatekeeper: true,
    viewer: true,
    manager: true,
  },
  {
    description: 'Utiliser NEOS Pro',
    gatekeeper: true,
    viewer: true,
    manager: true,
  },
  {
    description: 'Voir le détail des magasins',
    gatekeeper: false,
    viewer: true,
    manager: true,
  },
  {
    description: 'Consulter les rapports',
    gatekeeper: false,
    viewer: true,
    manager: true,
  },
  {
    description: 'Consulter les statistiques',
    gatekeeper: false,
    viewer: true,
    manager: true,
  },
  {
    description: 'Voir les utilisateurs',
    gatekeeper: false,
    viewer: false,
    manager: true,
  },
  {
    description: 'Créer/modifier les utilisateurs',
    gatekeeper: false,
    viewer: false,
    manager: true,
  },
  {
    description: 'Rembourser un achat',
    gatekeeper: false,
    viewer: false,
    manager: true,
  },
];

const RoleHelper = ({ role }) => {
  const [open, setOpen] = useState(false);

  let details = null;
  if (role === 'store_manager') {
    details = 'Un(e) responsable peut consulter les achats, les statistiques, créer des comptes et rembourser des achats.';
  } else if (role === 'viewer') {
    details = 'Un(e) observateur peut seulement consulter les achats et les statistiques. Il/Elle ne peut ni créer de compte, ni rembourser d’achat.';
  } else if (role === 'admin') {
    details = 'Un(e) administrateur a accès à tous les magasins et dispose tous les droits.';
  }

  return (
    <div>
      {details ? (<>{details}<br /></>) : null}
      <Button type="link" onClick={() => setOpen(!open)} style={{ paddingLeft: 0 }}>En savoir plus sur les rôles</Button>
      {open && (
        <div>
          <Table>
            <thead>
              <tr>
                <th></th>
                <Th active={role === 'gatekeeper'}>Vigile</Th>
                <Th active={role === 'viewer'}>Observateur</Th>
                <Th active={role === 'store_manager'}>Responsable</Th>
              </tr>
            </thead>
            <tbody>
              {rights.map((right, i) => (
                <tr key={i}>
                  <Action>{right.description}</Action>
                  <Td active={role === 'gatekeeper'}>{right.gatekeeper && '•'}</Td>
                  <Td active={role === 'viewer'}>{right.viewer && '•'}</Td>
                  <Td active={role === 'store_manager'}>{right.manager && '•'}</Td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default ({ allowAdmin, options = {}, ...props }) => {
  const [role, setRole] = useState(options.initialValue);

  const onChange = (role) => {
    if (options.onChange !== undefined)
      options.onChange(role);
    setRole(role);
  };

  const roleHelp = () => {
    return <RoleHelper role={role} />;
  };
  return (
    <Form.Item name='role' help={roleHelp()} label="Rôle" {...props} {...options}>
      <Select onChange={onChange}>
        <Select.Option value="gatekeeper"><BackofficeUserRole userRole="gatekeeper" /></Select.Option>
        <Select.Option value="viewer"><BackofficeUserRole userRole="viewer" /></Select.Option>
        <Select.Option value="store_manager"><BackofficeUserRole userRole="store_manager" /></Select.Option>
        {allowAdmin && (
          <Select.Option value="admin"><BackofficeUserRole userRole="admin" /></Select.Option>
        )}
      </Select>
    </Form.Item>
  );
};
