import React, { useState, useContext } from 'react';
import { SessionContext } from '../../context/session-context';
import { Input, Row, Col, Button, Alert, Radio, Form } from 'antd';
import SelectStore from '../../components/SelectStore/SelectStore';
import SelectPartner from '../../components/SelectPartner/SelectPartner';
import { createBackofficeUser } from '../../services/api';
import { Link } from 'react-router-dom';
import SelectRole from '../../components/BackofficeUser/FormSelectRole';
import { validatePasswordRules } from '../../utils/PasswordUtils';

function randomstring(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
const radioStyle = {
  display: 'block',
  minHeight: '30px',
  whiteSpace: 'normal',
};

const CreateBackofficeUser = () => {
  const [session] = useContext(SessionContext);
  const [error, setError] = useState(undefined);
  const [createByInvite, setCreateByInvite] = useState(true);
  const [email, setEmail] = useState('');
  const [success_confirmation, setSuccessConfirmation] = useState(false);
  const [invitedEmail, setInvitedEmail] = useState();
  const [role, setRole] = useState();
  const [stores, setStores] = useState([]);
  const [partners, setPartners] = useState([]);
  const [given_name, setGivenName] = useState();
  const [family_name, setFamilyName] = useState();
  const [password, setPassword] = useState();
  const [password_confirmation, setPasswordConfirmation] = useState();

  const [form] = Form.useForm();

  const handleSubmit = () => {
    setError(undefined);
    setSuccessConfirmation(false);

    const fields = {};
    if (createByInvite) {
      fields.given_name = ' ';
      fields.family_name = ' ';
      fields.password = randomstring(18);
      fields.password_confirmation = fields.password;
      fields.invite = true;
    } else {
      fields.given_name = given_name;
      fields.family_name = family_name;
      fields.password = password;
      fields.password_confirmation = password_confirmation;
    }
    fields.email = email.toLocaleLowerCase();
    fields.stores = stores || [];
    fields.partners = partners || [];
    fields.role = role;

    createBackofficeUser(fields)
      .then(() => {
        setSuccessConfirmation(true);
        setEmail('');
        form.setFieldsValue({
          email: '',
        });
        setInvitedEmail(createByInvite ? email : null);
        if (!createByInvite) {
          setEmail('');
          setGivenName('');
          setFamilyName('');
          setPassword('');
          setPasswordConfirmation('');
          form.resetFields();
        }
      })
      .catch((error) => {
        setSuccessConfirmation(false);
        if (error.response?.data)
          setError(error.response.data.error);
        else
          setError('Impossible de créer le compte');
      });
  };

  const passwordFieldsNotEmpty = () => {
    return password || password_confirmation;
  };

  const canSubmit = () => {
    if (
      email.length < 5 ||
      !role ||
      (role !== 'admin' &&
        (!stores || stores.length === 0) &&
        (!partners || partners.length === 0))
    ) {
      return false;
    }
    if (!createByInvite) {
      if (!given_name || !family_name || !password || !password_confirmation) {
        return false;
      }
      return canSubmitPassword();
    }
    return true;
  };

  const canSubmitPassword = () => {
    return validatePasswordRules(password, password_confirmation);
  };

  const onValuesChange = ({ email: newEmail, role, stores, partners, family_name, given_name, password, password_confirmation }) => {
    if (newEmail !== undefined) {
      if (newEmail && newEmail !== email && Math.abs(newEmail.length - (email || '').length) > 10) {
        const emailRegex =
          /(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})/gim;
        const m = newEmail.match(emailRegex);
        if (m && m.length > 0 && m[0] !== newEmail) {
          newEmail = m[0];
        }
      }
      setEmail(newEmail);
    }
    if (role !== undefined)
      setRole(role);
    if (stores !== undefined)
      setStores(stores);
    if (partners !== undefined)
      setPartners(partners);
    if (given_name !== undefined)
      setGivenName(given_name);
    if (family_name !== undefined)
      setFamilyName(family_name);
    if (password !== undefined)
      setPassword(password);
    if (password_confirmation !== undefined)
      setPasswordConfirmation(password_confirmation);
  };

  const selectedPartners = session.user.partners || [];

  return (
    <div>
      <h2 style={{ textAlign: 'center' }}>Créer un nouvel utilisateur backoffice</h2>
      <Row>
        <Col md={16} xs={24}>
          <Form onFinish={handleSubmit} onValuesChange={onValuesChange} form={form}>
            <Form.Item {...formItemLayout} name='email' label="Adresse email">
              <Input
                type="email"
                placeholder="marie.curie@gmail.com"
              />
            </Form.Item>
            <SelectRole {...formItemLayout} allowAdmin={session.user.can_create_admins} />
            {(!session.user.can_create_admins ||
              (role && role !== 'admin')) && (
              <>
                <Form.Item
                  {...formItemLayout}
                  name='partners'
                  label="Enseignes accessibles"
                  help="Utilisez ce champ pour donner accès à tous les magasins d’une enseigne."
                >
                  <SelectPartner
                    partners={session.user.partners}
                    allOption={false}
                    multiple={true}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  name='stores'
                  label="Magasins accessibles"
                  help="Utilisez ce champ pour donner accès à certains magasins seulement."
                >
                  <SelectStore
                    stores={session.user.all_stores.filter(
                      (store) => selectedPartners.indexOf(store.partner.id) === -1,
                    )}
                    allOption={false}
                    multiple={true}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item {...tailFormItemLayout}>
              <Radio.Group
                onChange={(e) => setCreateByInvite(e.target.value)}
                value={createByInvite}
              >
                <Radio style={radioStyle} value={true}>
                  Envoyer une invitation par email à {email} pour l’inviter à terminer la création de son compte
                </Radio>
                <Radio style={radioStyle} value={false}>
                  Ne pas envoyer d’invitation{'\u00A0'}: je veux remplir moi-même toutes les informations
                </Radio>
              </Radio.Group>
            </Form.Item>
            {!createByInvite && (
              <>
                <Form.Item {...formItemLayout} name='given_name' label="Prénom">
                  <Input type="text" placeholder="Marie" />
                </Form.Item>
                <Form.Item {...formItemLayout} name='family_name' label="Nom">
                  <Input type="text" placeholder="Curie" />
                </Form.Item>
                <Form.Item {...formItemLayout} name='password' label="Mot de passe">
                  <Input type="password" placeholder="********" />
                </Form.Item>
                <Form.Item {...formItemLayout} name='password_confirmation' label="Confirmation du mot de passe">
                  <Input type="password" placeholder="********" />
                </Form.Item>
              </>
            )}
            {error && <Alert message={error} type="error" />}
            {passwordFieldsNotEmpty() && !canSubmitPassword() && (
              <Alert
                message={
                  <span>
                    Votre mot de passe doit contenir au moins dix caractères dont une majuscule, une minuscule, un
                    chiffre et un caractère spécial
                  </span>
                }
                type="info"
              />
            )}
            {success_confirmation && (
              <Alert
                message={
                  <span>
                    {invitedEmail
                      ? `Une invitation a bien été envoyée à ${invitedEmail}`
                      : 'Le compte a bien été créé.'}{' '}
                    <Link to="/users">Retour à la liste des comptes</Link>
                  </span>
                }
                type="success"
              />
            )}
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit" disabled={!canSubmit()}>
                Créer
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default CreateBackofficeUser;
