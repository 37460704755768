import React from 'react';

export default ({ width = 'calc(100% - 16px)', height = 284, style = {} }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0.9,
        background: 'repeating-linear-gradient(45deg, rgba(24, 144, 255, 0.2), rgba(24, 144, 255, 0.2) 20px, rgba(255, 255, 255, 0.2) 20px, rgba(255, 255, 255, 0.2) 40px)',
        fontSize: '1.2em',
        fontVariant: 'small-caps',
        margin: 8,
        width,
        height,
        ...style,
      }}
    >
      Chargement...
    </div>
  );
};
