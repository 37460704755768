import React from 'react';
import PropTypes from 'prop-types';

const roles = {
  admin: 'Administrateur Lyf Pay',
  gatekeeper: 'Vigile',
  store_manager: 'Responsable',
  viewer: 'Observateur',
};

const BackofficeUserRole = ({ userRole }) => {
  return (
    <span>{roles[userRole]}</span>
  );
};

BackofficeUserRole.propTypes = {
  userRole: PropTypes.string.isRequired,
};

export default BackofficeUserRole;
