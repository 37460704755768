import React from 'react';
import { Tag } from 'antd';

const statusToInfos = {
  charge_created: { color: 'blue', text: 'Initié' },
  charge_processing: { color: 'orange', text: 'En traitement' },
  charge_suspicious: { color: 'orange', text: 'Bloqué' },
  charge_processed: { color: 'green', text: 'Terminé' },
  charge_errored: { color: 'red', text: 'En erreur' },
  refunded: { color: 'blue', text: 'Remboursé' },
};

const ChargeStatus = ({ status, style }) => {
  const defaultInfos = { text: 'État inconnu' };
  const infos = statusToInfos[status] || defaultInfos;
  return (
    <Tag style={style} color={infos.color}>{infos.text}</Tag>
  );
};

export default ChargeStatus;
