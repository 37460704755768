import React, { useState } from 'react';
import { ScanOutlined, UserOutlined } from '@ant-design/icons';
import { Button, List } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
`;
const LightLabel = styled.div`
  color: gray;
  font-size: 0.75rem;
`;

const MissingBarcodeLine = ({ barcode, name, count, users_count, imported_product_name, first_time, last_time }) => (
  <List.Item>
    <div style={{ flex: 1, opacity: imported_product_name ? 0.6 : 1 }}>
      <LineContainer>
        <div style={{ fontWeight: 'bold', fontSize: '1rem', textDecoration: imported_product_name ? 'line-through' : 'none' }}>{barcode}</div>
        <LightLabel>{moment(first_time).calendar(null, { sameElse: 'DD/MM/YYYY [à] HH:mm' })}</LightLabel>
      </LineContainer>
      <LineContainer>
        <div>
          {imported_product_name || (name ? (<a href={`https://world.openfoodfacts.org/product/${barcode}/`} target="_blank" rel="noopener noreferrer">{name}</a>) : undefined)}
        </div>
        <LightLabel>↓</LightLabel>
      </LineContainer>
      <LineContainer>
        <div>
          <ScanOutlined /> {count} • <UserOutlined /> {users_count}
        </div>
        <LightLabel>
          {moment(last_time).calendar(null, { sameElse: 'DD/MM/YYYY [à] HH:mm' })}
        </LightLabel>
      </LineContainer>
    </div>
  </List.Item>
);

const MissingBarcodesList = (props) => {
  const [state, setState] = useState({
    nbDisplayed: 20,
  });

  const { barcodes, loading, notReferencedOnly = true } = props;

  const loadMoreButton = props.barcodes.length > state.nbDisplayed ? (
    <div style={{
      textAlign: 'center', marginTop: 12, height: 32, lineHeight: '32px',
    }}
    >
      <Button onClick={() => setState({ ...state, nbDisplayed: state.nbDisplayed + 20 })} loading={loading}>Voir plus</Button>
    </div>
  ) : null;

  return (
    <div>
      <List
        dataSource={barcodes.filter(barcode => !notReferencedOnly || !barcode.imported_product_name).filter((_barcode, i) => i < state.nbDisplayed)}
        renderItem={barcode => (<MissingBarcodeLine {...barcode} />)}
        size="small"
        loading={loading}
        loadMore={loadMoreButton}
      />
    </div>
  );
};

MissingBarcodesList.propTypes = {
  barcodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  notReferencedOnly: PropTypes.bool,
  loading: PropTypes.bool,
};

export default MissingBarcodesList;
