import React from 'react';
import { Table, Tooltip } from 'antd';
import moment from 'moment';
import './MissingBarcodesTable.css';
import PropTypes from 'prop-types';

const MissingBarcodesTable = ({ barcodes, loading, notReferencedOnly = true }) => {
  const columns = [
    {
      title: 'Code-barre',
      dataIndex: 'barcode',
    },
    {
      title: (<div style={{ textAlign: 'center' }}><div style={{ fontSize: '0.6em' }}>Nombre de</div><div>scans</div></div>),
      dataIndex: 'nb',
      sorter: (a, b) => a.nb - b.nb,
    },
    {
      title: (<div style={{ textAlign: 'center' }}><div style={{ fontSize: '0.6em' }}>Nombre d’</div><div>utilisateurs</div></div>),
      dataIndex: 'nbUsers',
      sorter: (a, b) => a.nbUsers - b.nbUsers,
    },
    {
      title: 'Nom',
      dataIndex: 'name',
    },
    {
      title: 'Premier scan',
      dataIndex: 'firstTime',
      sorter: (a, b) => a.firstTimeRaw.diff(b.firstTimeRaw),
    },
    {
      title: 'Dernier scan',
      dataIndex: 'lastTime',
      sorter: (a, b) => a.lastTimeRaw.diff(b.lastTimeRaw),
    },
  ];
  const data = barcodes.filter((barcode) => !notReferencedOnly || !barcode.imported_product_name).map((barcode) => {
    return {
      key: barcode.barcode,
      nb: barcode.count,
      nbUsers: barcode.users_count,
      name: barcode.imported_product_name || (barcode.name ? (<a href={`https://world.openfoodfacts.org/product/${barcode.barcode}/`} target="_blank" rel="noopener noreferrer">{barcode.name}</a>) : undefined),
      barcode: barcode.imported_product_name ? (<Tooltip title="Ce produit est désormais référencé"><span style={{ textDecoration: 'line-through' }}>{barcode.barcode}</span></Tooltip>) : barcode.barcode,
      firstTimeRaw: moment(barcode.first_time),
      lastTimeRaw: moment(barcode.last_time),
      firstTime: moment(barcode.first_time).calendar(null, { sameElse: 'DD/MM/YYYY [à] HH:mm' }),
      lastTime: moment(barcode.last_time).calendar(null, { sameElse: 'DD/MM/YYYY [à] HH:mm' }),
      referenced: !!barcode.imported_product_name,
    };
  });

  return (
    <div>
      <Table
        bordered
        loading={loading}
        dataSource={data}
        columns={columns}
        pagination={{
          defaultPageSize: 20,
        }}
        rowClassName={(barcode) => {
          return barcode.referenced ? 'Barcode-referenced' : '';
        }}
      />
    </div>
  );
};

MissingBarcodesTable.propTypes = {
  barcodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  notReferencedOnly: PropTypes.bool,
  loading: PropTypes.bool,
};

export default MissingBarcodesTable;
