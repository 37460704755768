import React from 'react';
import { Card } from 'antd';

export default ({ icon, title, children, ...props }) => {
  return (
    <Card
      title={(<span>{title} {icon}</span>)}
      size="small"
      {...props}
    >
      {children}
    </Card>
  );
};
