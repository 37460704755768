import React from 'react';
import { withRouter } from '../../hooks/withRouter';
import useUser from '../../hooks/useUser';
import { updateAppUser } from '../../services/api';
import { Button, Card, Row, Col, Spin, Form } from 'antd';
import { SelectStoreAllUserStoresComponent } from '../../components/SelectStore/SelectStoreAllUserStores';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const EditBackofficeUser = ({ params, navigate }) => {
  const user = useUser(params.userId);

  if (!user) {
    return (<div style={{ textAlign: 'center', maginTop: 16 }}><Spin size="large" tip={`Chargement du queuebuster  ${params.userId}...`} /></div>);
  }

  const onSubmit = async ({ stores }) => {
    await updateAppUser(user.id, { stores: stores || [] });
    navigate(`/queuebusting/users/${user.id}`);
  };

  return (
    <Row type="flex" justify="center">
      <Col xs={24} md={16} lg={14}>
        <Form onFinish={onSubmit}>
          <Card
            title={`Modifier ${user.email}`}
            actions={[
              <Button key="1" type="primary" htmlType="submit">Enregistrer</Button>,
              <Button key="2" type="default" onClick={() => navigate(`/queuebusting/users/${user.id}`)}>Annuler</Button>,
            ]}
          >
            <Form.Item {...formItemLayout} name='stores' label="Magasins accessibles" help="Utilisez ce champ pour donner accès à certains magasins seulement." initialValue={user.stores.map(s => s.id)}>
              <SelectStoreAllUserStoresComponent
                allOption={false}
                multiple={true}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Card>
        </Form>
      </Col>
    </Row>
  );
};

export default withRouter(EditBackofficeUser);
