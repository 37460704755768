/* eslint-disable */

export const API_URL = process.env.REACT_APP_API_URL;
export const ENV = process.env.REACT_APP_ENV;

export const COLORS = [
  '#40a9ff',
  '#ff6b70',
  '#ffc53d',
  '#ffe7ba',
  '#8c8c8c',
  '#eb2f96',
];
