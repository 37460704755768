import React, { useState, useEffect, useContext } from 'react';
import { SessionContext } from '../../context/session-context';
import SelectStore from '../../components/SelectStore/SelectStore';
import moment from 'moment';
import { getPurchasesStats, getPurchasedProductsStats, getUsersStats, getLoyaltyStats } from '../../services/api';
import { Route, Routes } from 'react-router-dom';
import Sales from './Sales';
import Traffic from './Traffic';
import Loyalty from './Loyalty';
import Responsive from 'react-responsive';

export default () => {
  const [session] = useContext(SessionContext);
  const [storesIds, setStoresIds] = useState([]);
  const [partnersIds, setPartnersIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [purchasesByDay, setPurchasesByDay] = useState([]);
  const [purchasesByWeek, setPurchasesByWeek] = useState([]);
  const [purchasesByMonth, setPurchasesByMonth] = useState([]);
  const [purchasedProductsStats, setPurchasedProductsStats] = useState({});
  const [usersStats, setUsersStats] = useState([]);
  const [loyaltyStats, setLoyaltyStats] = useState([]);

  const data = {
    loading,
    purchasesByDay,
    purchasesByWeek,
    purchasesByMonth,
    purchasedProductsStats,
    usersStats,
    loyaltyStats,
    storesIds,
    partnersIds,
  };

  useEffect(() => {
    setLoading(true);

    const purchasesByDayPromise = getPurchasesStats(storesIds, partnersIds, moment().subtract(1, 'year').startOf('day')).then(setPurchasesByDay);
    const purchasesByWeekPromise = getPurchasesStats(storesIds, partnersIds, moment().subtract(1, 'year').startOf('week'), 'week').then(setPurchasesByWeek);
    const purchasesByMonthPromise = getPurchasesStats(storesIds, partnersIds, moment().subtract(1, 'year').startOf('month'), 'month').then(setPurchasesByMonth);
    const purchasedProductsStatsPromise = getPurchasedProductsStats(storesIds, partnersIds, moment().subtract(1, 'year').startOf('month').startOf('week')).then(setPurchasedProductsStats);
    const usersStatsPromise = getUsersStats(storesIds, partnersIds).then(setUsersStats);
    const loyaltyStatsPromise = getLoyaltyStats(storesIds, partnersIds, 'month').then(setLoyaltyStats);

    Promise.all([
      purchasesByDayPromise,
      purchasesByWeekPromise,
      purchasesByMonthPromise,
      purchasedProductsStatsPromise,
      usersStatsPromise,
      loyaltyStatsPromise,
    ]).then(() => setLoading(false));
  }, [storesIds, partnersIds]);

  const storeFilter = (
    <div style={{ minHeight: 48, paddingTop: 8, borderBottom: '1px solid rgb(232, 232, 232)', width: '21%' }}>
      <SelectStore
        stores={session.user.all_stores}
        partners={session.user.partners}
        onChange={(s, p, withPartners) => {
          setStoresIds(s);
          withPartners && setPartnersIds(p);
        }}
        multiple
        style={{ width: '100%' }}
        placeholder="Tous les magasins"
        defaultStores={storesIds}
        defaultPartners={partnersIds}
      />
    </div>
  );

  return (
    <div>
      <Responsive maxWidth={767}>
        <SelectStore
          stores={session.user.all_stores}
          partners={session.user.partners}
          onChange={(s, p, withPartners) => {
            setStoresIds(s);
            withPartners && setPartnersIds(p);
          }}
          multiple
          style={{ width: '100%' }}
          placeholder="Tous les magasins"
        />
      </Responsive>

      <Responsive minWidth={768}>
        {(largeScreen) => (
          <div style={{ marginLeft: largeScreen ? '0' : -16, marginRight: largeScreen ? '0' : -16, marginTop: largeScreen ? -16 : 0 }}>
            <Routes>
              <Route path="/sales/*" element={<Sales {...data} storeFilter={largeScreen ? storeFilter : null} />} />
              <Route path="/traffic/*" element={<Traffic {...data} storeFilter={largeScreen ? storeFilter : null} />} />
              <Route path="/loyalty/*" element={<Loyalty {...data} storeFilter={largeScreen ? storeFilter : null} />} /> 
            </Routes>
          </div>
        )}
      </Responsive>
    </div>
  );
};
