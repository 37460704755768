import React, { useState, useEffect } from 'react';
import { getStore } from '../../services/api';
import StoreCard from '../../components/Store/StoreCard';

export default ({ params }) => {
  const [store, setStore] = useState();
  useEffect(() => {
    getStore(params.storeId).then(setStore);
  }, [params.storeId]);

  return (
    <StoreCard store={store} loading={!store} />
  );
};
