import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from '../../hooks/withRouter';
import { SessionContext } from '../../context/session-context';
import useBackofficeUser from '../../hooks/useBackofficeUser';
import { updateBackofficeUser } from '../../services/api';
import { Button, Card, Row, Col, Spin, Form } from 'antd';
import SelectRole from '../../components/BackofficeUser/FormSelectRole';
import SelectStore from '../../components/SelectStore/SelectStore';
import SelectPartner from '../../components/SelectPartner/SelectPartner';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const EditBackofficeUser = ({ params, navigate }) => {
  const [session] = useContext(SessionContext);
  const [user] = useBackofficeUser(params.userId);
  const [displayStores, setDisplayStores] = useState(false);

  useEffect(() => {
    if (user) {
      setDisplayStores(user.role !== 'admin');
    }
  }, [user]);

  if (!user) {
    return (<div style={{ textAlign: 'center', maginTop: 16 }}><Spin size="large" tip={`Chargement de l’utilisateur ${params.userId}...`} /></div>);
  }

  const selectedPartners = user.partners.map(p => p.id) || [];
  const userStores = user.stores.map(s => s.id) || [];

  const onSubmit = async ({ role, partners, stores }) => {
    await updateBackofficeUser(user.id, { role, partners: partners || [], stores: stores || [] });
    navigate(`/users/${user.id}`);
  };

  return (
    <Row type="flex" justify="center">
      <Col xs={24} md={16} lg={14}>
        <Form onFinish={onSubmit}>
          <Card
            title={`Modifier ${user.email}`}
            actions={[
              <Button key="1" type="primary" htmlType="submit">Enregistrer</Button>,
              <Button key="2" type="default" onClick={() => navigate(`/users/${user.id}`)}>Annuler</Button>,
            ]}
          >
            <SelectRole
              {...formItemLayout}
              allowAdmin={session.user.can_create_admins}
              options={{ initialValue: user.role, onChange: (role) => setDisplayStores(role !== 'admin') }}
            />
            {displayStores && (
              <>
                <Form.Item {...formItemLayout} name='partners' label="Enseignes accessibles" help="Utilisez ce champ pour donner accès à tous les magasins d’une enseigne." initialValue={user.partners.map(p => p.id)}>
                  <SelectPartner
                    partners={session.user.partners}
                    allOption={false}
                    multiple={true}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item {...formItemLayout} name='stores' label="Magasins accessibles" help="Utilisez ce champ pour donner accès à certains magasins seulement." initialValue={userStores}>
                  <SelectStore
                    stores={session.user.all_stores.filter(store => (selectedPartners.indexOf(store.partner.id) !== -1)) }
                    allOption={false}
                    multiple={true}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </>
            )}
          </Card>
        </Form>
      </Col>
    </Row>
  );
};

export default withRouter(EditBackofficeUser);
