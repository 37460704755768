import React from 'react';
import { HeartTwoTone, StarTwoTone, ThunderboltTwoTone } from '@ant-design/icons';
import { List, Button } from 'antd';
import moment from 'moment';
import Price from '../Price/Price';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ChargeStatus from '../ChargeStatus/ChargeStatus';
import Platform from '../Platform/Platform';
import styled from 'styled-components';
import { shortCalendar } from '../../services/time';

const truncateString = (text, length) => {
  if (text.length >= length)
    return text.slice(0, length - 4) + '...';
  else
    return text;
};

const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
`;
const PurchaseLine = ({ id, short_identifier, store, total_price, created_at, dateCalendar, charge_status, platform, platform_version, is_queuebusting, first_purchase, first_purchase_in_store, total_products_count }) => (
  <List.Item>
    <Link style={{ flex: 1, color: 'rgba(0, 0, 0, 0.65)' }} to={`/purchases/${id}`}>
      <LineContainer>
        <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>{short_identifier}</div>
        <ChargeStatus status={charge_status} style={{ marginRight: 0 }} />
      </LineContainer>
      <LineContainer>
        <div>
          {dateCalendar ? shortCalendar(created_at) : moment(created_at).format('ddd DD/MM [à] HH:mm')}
          <span> • </span>
          <Platform platform={platform} version={platform_version} />
          {is_queuebusting && !platform_version.match(/mpos/) && (
            <ThunderboltTwoTone />
          )}
          {!is_queuebusting && first_purchase && (
            <StarTwoTone twoToneColor="#52c41a" />
          )}
          {!is_queuebusting &&!first_purchase && first_purchase_in_store && (
            <HeartTwoTone twoToneColor="#eb2f96" />
          )}
        </div>
        <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>
          <Price {...total_price} />
        </div>
      </LineContainer>
      <LineContainer>
        <div style={{ flex: 1, width: 50 }}>
          { truncateString(store.name, 40) }
        </div>
        <div><b>{total_products_count}</b> article{total_products_count > 1 ? 's' : ''}</div>
      </LineContainer>
    </Link>
  </List.Item>
);

const PurchasesList = ({ purchases, loading, maxResults, dateCalendar = false, onLoadMore }) => {
  const loadMoreButton = onLoadMore ? (
    <div style={{
      textAlign: 'center', marginTop: 12, height: 32, lineHeight: '32px',
    }}
    >
      <Button onClick={onLoadMore} loading={loading}>Voir plus</Button>
    </div>
  ) : null;

  return (
    <List
      dataSource={purchases.filter((_purchase, i) => maxResults === undefined || i < maxResults)}
      renderItem={purchase => (<PurchaseLine {...purchase} dateCalendar={dateCalendar} />)}
      size="small"
      loading={!onLoadMore && loading}
      loadMore={loadMoreButton}
    />
  );
};

PurchasesList.propTypes = {
  purchases: PropTypes.arrayOf(PropTypes.shape({
    store: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
  })).isRequired,
  maxResults: PropTypes.number,
  dateCalendar: PropTypes.bool,
};

export default PurchasesList;
