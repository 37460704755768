import React, { useState, useEffect, useRef } from 'react';
import Purchase from './Purchase';
import { getPurchase } from '../../services/api';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import useConstructor from '../../utils/UseConstructor';

const PurchaseData = (props) => {
  const [state, setState] = useState({
    purchase: undefined,
  });
  const purchaseId = useRef(props.purchaseId);

  const getPurchasePurchaseData = async () => {
    const purchase = await getPurchase(props.purchaseId);
    setState({
      ...state,
      purchase: purchase.data.purchase,
    });
  };

  useConstructor(() => {
    getPurchasePurchaseData();
  });

  useEffect(() => {
    if (props.purchaseId !== purchaseId.current) {
      getPurchasePurchaseData();
      purchaseId.current = props.purchaseId;
    }
  });

  if (state.purchase === undefined) {
    return (<div style={{ textAlign: 'center', maginTop: 16 }}><Spin size="large" tip={`Chargement de l’achat ${props.purchaseId}...`} /></div>);
  }

  return (
    <Purchase purchase={state.purchase} handleUpdate={() => getPurchasePurchaseData()} />
  );
};

PurchaseData.propTypes = {
  purchaseId: PropTypes.string.isRequired,
};

export default PurchaseData;
