import React, { useState, useContext } from 'react';
import { SessionContext } from '../../context/session-context';
import { Button, Card, DatePicker, Col, Row, Form } from 'antd';
import SelectStore from '../../components/SelectStore/SelectStore';
import moment from 'moment';
import { withRouter } from '../../hooks/withRouter';
import Responsive from 'react-responsive';

const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const Report = ({ navigate }) => {
  const [session] = useContext(SessionContext);
  const [dateValue, setDateValue] = useState(moment().subtract('1', 'day'));
  const [minDateValue, setMinDateValue] = useState(moment().subtract('2', 'weeks'));
  const [maxDateValue, setMaxDateValue] = useState(moment().subtract('1', 'day'));

  const onSubmitDaily = ({ store, date }) => {
    navigate(`/purchases/report/detail/${store}/${date.format('YYYY-MM-DD')}`);
  };
  const onSubmitSynthesis = ({ minDate, maxDate }) => {
    navigate(`/purchases/report/global/${minDate.format('YYYY-MM-DD')}/${maxDate.format('YYYY-MM-DD')}`);
  };

  const onValuesChange = ({date, minDate, maxDate}) => {
    if (date !== undefined)
      setDateValue(date);
    if (minDate !== undefined)
      setMinDateValue(minDate);
    if (maxDate !== undefined)
      setMaxDateValue(maxDate);
  };

  const dailyFields = (
    <React.Fragment>
      <Responsive minWidth={768}>
        <Form.Item {...formItemLayout} name='store' label="Magasin concerné" initialValue={session.user.all_stores[0].id}>
          <SelectStore allOption={false} stores={session.user.all_stores} />
        </Form.Item>
      </Responsive>
      <Form.Item {...formItemLayout} name='date' label="Date" style={{ marginBottom: 0 }} initialValue={moment().subtract('1', 'day')}>
        <DatePicker style={{ width: 200 }} format="DD/MM/YYYY" />
      </Form.Item>
    </React.Fragment>
  );
  const globalFields = (
    <React.Fragment>
      <Form.Item {...formItemLayout} name='minDate' label="Date de début" initialValue={moment().subtract('2', 'weeks')}>
        <DatePicker style={{ width: 200 }} format="DD/MM/YYYY" />
      </Form.Item>
      <Form.Item {...formItemLayout} name='maxDate' label="Date de fin" style={{ marginBottom: 0 }} initialValue={moment().subtract('1', 'day')}>
        <DatePicker style={{ width: 200 }} format="DD/MM/YYYY" />
      </Form.Item>
    </React.Fragment>
  );

  return (
    <Responsive maxWidth={767}>
      {(smallScreeen) => (
        <Row gutter={{ md: 16, xs: 0 }} type="flex" style={smallScreeen ? { marginLeft: -16, marginRight: -16 } : null}>
          <Col md={12} style={{ display: 'flex' }}>
            <Form onFinish={onSubmitDaily} style={{ display: 'flex' }} onValuesChange={onValuesChange}>
              <Card
                title="Rapport journalier des paniers et produits"
                actions={[
                  <Button key="1" type="primary" htmlType="submit" disabled={!dateValue}>Consulter le rapport détaillé du magasin</Button>,
                ]}
                style={{ display: 'flex', flexDirection: 'column', marginBottom: smallScreeen ? 16 : 0 }}
                bodyStyle={{ flex: 1 }}
              >
                <p>
                  Ce rapport liste tous les paniers et tous les articles achetés via Lyf Pay sur une journée pour un magasin, avec les codes-barres associés.
                </p>
                {dailyFields}
              </Card>
            </Form>
          </Col>

          <Col md={12}>
            <Form onFinish={onSubmitSynthesis} onValuesChange={onValuesChange}>
              <Card
                title="Synthèse des ventes"
                actions={[
                  <Button key="1" type="primary" htmlType="submit" disabled={!minDateValue || !maxDateValue}>Consulter la synthèse journalière</Button>,
                ]}
              >
                <p>
                  Ce rapport indique le montant total des ventes et des réductions, pour tous vos magasins, de manière quotidienne et globale, pour la période de temps sélectionnée.
                </p>
                {globalFields}
              </Card>
            </Form>
          </Col>
        </Row>
      )}
    </Responsive>
  );
};

export default withRouter(Report);
