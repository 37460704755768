import ExcelJS from 'exceljs';

const ExcelUint8Buffer = function (worksheets) {
  let wb = new ExcelJS.Workbook();

  worksheets.forEach(function (elt) {
    let ws = wb.addWorksheet(elt['worksheetName']);

    ws.columns = elt['columns'];
    ws.addRows(elt['data']);
  });

  return wb.xlsx.writeBuffer();
};

export {
  ExcelUint8Buffer,
};