import React, { useState, useEffect, useMemo } from 'react';
import { withRouter } from '../../hooks/withRouter';
import SelectStores from '../../components/SelectStore/SelectStoresURLState';
import PurchasesTableData from '../../components/PurchasesTable/PurchasesTableData';
import MoneyDateChart from '../../components/MoneyDateChart/MoneyDateChart';
import DateChart from '../../components/DateChart/DateChart';
import { ReloadOutlined, TableOutlined, LineChartOutlined } from '@ant-design/icons';
import { Button, Row, Col, Switch, Card as AntCard, Badge } from 'antd';
import moment from 'moment';
import { getPurchasesStats, getStoresStats } from '../../services/api';
import StoresStats from '../../components/Store/StoresStats';
import Responsive from 'react-responsive';
import Card from '../../components/Card/Card';

const minDate = moment()
  .subtract(2, 'week')
  .startOf('day');

const Dashboard = ({ location }) => {
  const [storesIds, partnersIds] = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const storesIds = params.get('stores')
      ? params.get('stores').split(/,/g)
      : [];
    const partnersIds = params.get('partners')
      ? params.get('partners').split(/,/g)
      : [];

    return [storesIds, partnersIds];
  }, [location.search]);

  const [purchasesStatsLoading, setPurchasesStatsLoading] = useState(true);
  const [storesStatsLoading, setStoresStatsLoading] = useState(true);
  const [storesStats, setStoresStats] = useState();
  const [purchasesStats, setPurchasesStats] = useState([]);
  const [version, setVersion] = useState(1);
  const [isLive, setIsLive] = useState(false);

  const updatePurchasesStats = async (storesIds, partnersIds) => {
    setPurchasesStatsLoading(true);
    const purchasesStats = await getPurchasesStats(
      storesIds,
      partnersIds,
      minDate,
    );
    setPurchasesStats(purchasesStats);
    setPurchasesStatsLoading(false);
  };
  const updateStoresStats = async (storesIds, partnersIds) => {
    setStoresStatsLoading(true);
    const storesStats = await getStoresStats(storesIds, partnersIds);
    setStoresStats(storesStats);
    setStoresStatsLoading(false);
  };

  useEffect(() => {
    updatePurchasesStats(storesIds, partnersIds);
    updateStoresStats(storesIds, partnersIds);
  }, [storesIds, partnersIds, version]);

  useEffect(() => {
    if (isLive) {
      const liveInterval = setInterval(
        () => setVersion(cversion => cversion + 1),
        10_000,
      );

      return () => {
        clearInterval(liveInterval);
      };
    }
  }, [isLive]);

  const reloadButton = (
    <Button
      shape="circle"
      icon={<ReloadOutlined />}
      type="primary"
      size="small"
      disabled={purchasesStatsLoading || storesStatsLoading}
      onClick={() => setVersion(version + 1)}
      ghost
      style={{ marginLeft: '0.75em' }}
    />
  );

  return (
    <div>
      <Responsive minWidth={768}>
        <AntCard size="small" style={{ marginBottom: 16 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              Filtrer par magasin{'\u00A0'}:
              <SelectStores
                style={{ marginLeft: '0.75em', width: '30vw' }}
                placeholder="Tous"
              />
              {reloadButton}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {isLive && <Badge status="processing" />}
              <Switch
                checkedChildren="Live"
                unCheckedChildren="Live"
                style={{ marginLeft: '0.25em' }}
                onChange={setIsLive}
              />
            </div>
          </div>
        </AntCard>
      </Responsive>

      <Responsive minWidth={768}>
        {largeScreen => (
          <StoresStats
            stats={storesStats}
            loading={!isLive && storesStatsLoading}
            mobile={!largeScreen}
          />
        )}
      </Responsive>

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card
            title="Achats récents"
            icon={<TableOutlined style={{ float: 'right', fontSize: 20 }} />}
            bodyStyle={{ padding: 0 }}
            style={{ marginBottom: 16 }}
          >
            <Responsive maxWidth={767}>
              <div style={{ padding: 16 }}>
                <SelectStores
                  style={{ width: 'calc(100% - 24px - 0.75rem)' }}
                  placeholder="Tous les magasins"
                />

                {reloadButton}
              </div>
            </Responsive>
            <Responsive minWidth={768}>
              <PurchasesTableData
                version={version}
                storesIds={storesIds}
                partnersIds={partnersIds}
                statusColumns={false}
                pagination={false}
                chargeStatus="charge_processed"
                dateCalendar
                idColumn={false}
                showHeader={false}
                showLoading={!isLive}
                animate={isLive}
              />
            </Responsive>
            <Responsive maxWidth={767}>
              <div style={{ marginLeft: 16, marginRight: 16 }}>
                <PurchasesTableData
                  version={version}
                  storesIds={storesIds}
                  partnersIds={partnersIds}
                  statusColumns={false}
                  pagination={false}
                  chargeStatus="charge_processed"
                  dateCalendar
                  maxResults={7}
                  display="list"
                />
              </div>
            </Responsive>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card
            title="Nombre d’achats par jour"
            icon={<LineChartOutlined style={{ float: 'right', fontSize: 20 }} />}
            bodyStyle={{ padding: 0 }}
            style={{ marginBottom: 16 }}
          >
            <DateChart
              data={purchasesStats}
              minDate={minDate}
              maxDate={moment()}
              series={[{ dataKey: 'nb_charged', label: 'Nombre d’achats' }]}
              title="Nombre d’achats"
              loading={!isLive && purchasesStatsLoading}
              height={250}
              type="line"
            />
          </Card>
          <Card
            title="Panier moyen par jour"
            icon={<LineChartOutlined style={{ float: 'right', fontSize: 20 }} />}
            bodyStyle={{ padding: 0 }}
          >
            <MoneyDateChart
              data={purchasesStats}
              minDate={minDate}
              maxDate={moment()}
              series={[
                {
                  dataKey: 'avg_charged',
                  label: 'Panier moyen (en valeur)',
                  color: 1,
                },
              ]}
              title="Panier moyen (en valeur)"
              loading={!isLive && purchasesStatsLoading}
              height={250}
              type="line"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(Dashboard);