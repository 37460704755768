function validatePasswordRules(password, passwordConfirmation) {
  return password && passwordConfirmation
    && password === passwordConfirmation
    && passwordLengthIsCorrect(password)
    && passwordContainsNumber(password)
    && passwordContainsUppercaseAndLowercase(password)
    && passwordContainsSpecialChar(password);
}

function validatePasswordEditRules(currentPassword, password, passwordConfirmation) {
  return currentPassword && validatePasswordRules(password, passwordConfirmation);
}

function passwordLengthIsCorrect(password) {
  return password.length >= 10;
}

function passwordContainsSpecialChar(password) {
  return /[~`¿¡!#$%^&*€£@+÷=\-[\]\\';,/{}()|\\":<>?._]/g.test(password);
}

function passwordContainsUppercaseAndLowercase(password) {
  return passwordContainsUppercase(password) && passwordContainsLowercase(password);
}

function passwordContainsUppercase(password) {
  var i = 0;
  if (password.length === 0) return false;
  while (i < password.length) {
    const char = password.charAt(i);
    if (char === char.toUpperCase()) {
      return true;
    }
    i++;
  }
}

function passwordContainsLowercase(password) {
  var i = 0;
  if (password.length === 0) return false;
  while (i < password.length) {
    const char = password.charAt(i);
    if (char === char.toLowerCase()) {
      return true;
    }
    i++;
  }
}

function passwordContainsNumber(password) {
  return /\d/g.test(password);
}

export {
  validatePasswordRules,
  validatePasswordEditRules,
};
