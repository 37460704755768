import React from 'react';
import Login from './Login';
import Cookies from '../Cookies/Cookies';
import ResetPassword from './ResetPassword';
import { Routes, Route } from 'react-router-dom';

export default () => (
  <Routes>
    <Route exact path="/reset-password" element={<ResetPassword />} />
    <Route path="/*" element={<Login />} />
    <Route exact path="/cookies" element={<Cookies />} />
  </Routes>
);
