import React, { useState, useEffect } from 'react';
import PurchasesTable from './PurchasesTable';
import PurchasesList from './PurchasesList';
import { getPurchases } from '../../services/api';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';

const PurchasesTableData = (props) => {
  const [state, setState] = useState({
    purchases: [],
    loading: false,
    page: props.page || 1,
  });

  const purchasesTableDataGetPurchases = async (params) => {
    setState({ ...state, loading: true });
    const purchases = await getPurchases(params);
    setState({
      ...state,
      page: purchases.current_page,
      loading: false,
      ...purchases,
    });
  };
  useEffect(() => {
    purchasesTableDataGetPurchases(props);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    purchasesTableDataGetPurchases({ ...props, page: 1 });
    // eslint-disable-next-line
  }, [props.storesIds.join(), props.partnersIds.join(), props.search, props.chargeStatus]);

  useEffect(() => {
    purchasesTableDataGetPurchases({ ...props, page: props.page });
    // eslint-disable-next-line
  }, [props.page]);

  useEffect(() => {
    purchasesTableDataGetPurchases(props);
    // eslint-disable-next-line
  }, [props.version])

  const handleTableChange = (pagination) => {
    props.onPageChange(pagination.current);
  };

  const onLoadMore = async () => {
    setState({ ...state, loading: true });
    const purchases = await getPurchases({ ...props, page: state.page + 1 });
    setState((state) => {
      const oldPurchases = state.purchases;
      purchases.purchases.forEach((purchase) => {
        const oldPurchase = oldPurchases.find((p) => p.id === purchase.id);
        if (oldPurchase) {
          Object.assign(oldPurchase, purchase);
        } else {
          oldPurchases.push(purchase);
        }
      });
      const newState = {
        ...state,
        purchases: orderBy(oldPurchases, ['created_at'], ['desc']),
        loading: false,
        page: purchases.current_page,
      };
      if (purchases.current_page > state.page || !state.nextPage) {
        newState.page = purchases.current_page;
        newState.nextPage = purchases.next_page;
      }
      return newState;
    });
  };

  const RenderComponent = props.display === 'list' ? PurchasesList : PurchasesTable;
  return (
    <RenderComponent
      storeColumn={!props.storesIds || props.storesIds.length !== 1 || (props.partnersIds && props.partnersIds.length > 0)}
      paymentTypesColumn={true}
      handleTableChange={handleTableChange}
      onLoadMore={props.loadMore && props.display === 'list' ? onLoadMore : null}
      {...props}
      {...state}
    />
  );
};

PurchasesTableData.propTypes = {
  storesIds: PropTypes.arrayOf(PropTypes.string),
  partnersIds: PropTypes.arrayOf(PropTypes.string),
  search: PropTypes.string,
  page: PropTypes.number,
  chargeStatus: PropTypes.string,
};

export default PurchasesTableData;
