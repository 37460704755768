import { useState, useEffect } from 'react';
import { getAppUser } from '../services/api';

export default (userId) => {
  const [user, setUser] = useState();

  useEffect(() => {
    getAppUser(userId).then(setUser);
  }, [userId]);

  return user;
};
