import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';

const Option = Select.Option;

const SelectPartner = (parameters) => {
  const { partners, allOption = false, multiple = false, style = {}, ...props } = parameters;
  const defaultValue = props.defaultValue || (multiple ? undefined : ((allOption || partners.length === 0) ? '' : partners[0].id));
  const mode = multiple ? 'multiple' : undefined;

  return (
    <Select
      {...props}
      defaultValue={defaultValue}
      style={{ width: 300, ...style }}
      mode={mode}
      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {allOption && (<Option value="">Tous</Option>)}
      {_.orderBy(partners, 'name').map((partner) => {
        return (
          <Option value={partner.id} key={partner.id}>{partner.name}</Option>
        );
      })}
    </Select>
  );
};

SelectPartner.propTypes = {
  partners: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  style: PropTypes.object,
  onChange: PropTypes.func,
  allOption: PropTypes.bool,
  multiple: PropTypes.bool,
};

export default SelectPartner;
