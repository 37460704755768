import React, { useContext } from 'react';
import SelectStore from './SelectStore';
import { withRouter } from '../../hooks/withRouter';
import { SessionContext } from '../../context/session-context';

const SelectStores = ({ ...props}) => {
  const [ session ] = useContext(SessionContext);
  const { user } = session;

  delete props.params; // We don't want this property to be passed down
  const partners = user.partners.sort((a,b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  const stores = user.all_stores.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  return (
    <SelectStore
      stores={stores}
      partners={partners}
      multiple
      {...props}
    />
  );
};

export default withRouter(SelectStores);
