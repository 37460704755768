import React from 'react';

export const Stat = ({ title, value, children }) => {
  let content = children;

  if (React.Children.count(children) === 0) {
    content = (<span style={{ fontWeight: 'bold', fontSize: '2rem' }}>{value}</span>);
  }

  return (
    <div style={{ textAlign: 'center', margin: 16 }}>
      <div style={{ textTransform: 'uppercase', fontWeight: 'light', fontSize: '0.75rem', letterSpacing: '0.05rem' }}>
        {title}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
        {content}
      </div>
    </div>
  );
};

export const SubStat = ({ title, value }) => (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '-0.8rem',
  }}
  >
    <div style={{ fontWeight: 'bold', fontSize: '2rem', marginBottom: '-0.3rem' }}>{value}</div>
    <div style={{ fontWeight: 500, fontSize: '0.8rem' }}>{title}</div>
  </div>
);

export const StatsRow = ({ children }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
    }}
  >
    {children}
  </div>
);
