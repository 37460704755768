import React, { useReducer, useEffect, createContext } from 'react';

export const SessionContext = createContext();

const updateUserRoles = (user) => {
  if (user.role === 'admin' || user.role === 'store_manager') {
    user.can_manage_users = true;
    user.can_refund = true;
    user.can_manage_queuebusting = true;
    user.can_export = true;
  }
  if (user.role === 'admin') {
    user.can_create_admins = true;
    user.can_export = true;
    user.can_see_users = true;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
  case 'USER_CONNECTED':
    return {
      ...state,
      isConnected: true,
    };
  case 'USER_DISCONNECTED':
    return {
      ...state,
      isConnected: false,
      user: null,
    };
  case 'USER_UPDATED':
    updateUserRoles(action.payload);
    return {
      ...state,
      user: action.payload,
    };
  default:
    throw new Error();
  }
};

export const SessionContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, { connected: false });

  useEffect(() => {
    if (state.user)
      dispatch({ type: 'USER_CONNECTED' });
  }, [state.user]);

  return (
    <SessionContext.Provider value={[state, dispatch]}>
      {props.children}
    </SessionContext.Provider>
  );
};