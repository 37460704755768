import React from 'react';
import Tabs from '../../components/Tabs/Tabs';
import { Route, Routes } from 'react-router-dom';
import MoneyDateChart from '../../components/MoneyDateChart/MoneyDateChart';
import DateChart from '../../components/DateChart/DateChart';
import NumericChart from '../../components/BarChart/NumericChart';
import _ from 'lodash';
import ChartsOverviewLastYear from '../../components/ChartsOverviewLastYear/ChartsOverviewLastYear';
import Responsive from 'react-responsive';
import { Card } from 'antd';

const pagesLargeScreen = [
  { url: 'volume', label: 'Chiffre d’affaires' },
  { url: 'basket-volume', label: 'Panier moyen (valeur)' },
  { url: 'basket-size', label: 'Panier moyen (volume)' },
];
const pagesSmallScreen = [
  { url: 'volume', label: 'CA' },
  { url: 'basket-volume', label: 'Paniers (valeur)' },
  { url: 'basket-size', label: 'Paniers (volume)' },
];

const avgItemsPerPurchaseReducer = (data) => {
  return _.sumBy(data, (d) => d.nb_purchased_products || 0) /(_.sumBy(data, (d) => d.nb_purchases || 0) || 1);
};

export default ({ purchasesByDay, purchasedProductsStats, loading, storeFilter }) => {
  const { purchasedProductsByDay = [], nbPurchasesPerNbProducts = [], nbPurchasesPerPrice = [] } = purchasedProductsStats;

  const BasketVolume = () => (
    <div>
      <ChartsOverviewLastYear
        component={MoneyDateChart}
        data={purchasesByDay}
        series={[{ dataKey: 'avg_charged', label: 'Panier moyen (en valeur)', reducer: 'avg' }]}
        title="Panier moyen (en valeur)"
        loading={loading}
      />
      <Card title="Nombre d’achats par valeur du panier">
        <NumericChart
          data={nbPurchasesPerPrice.filter(({ charged_price }) => charged_price < 200)}
          xKey="charged_price"
          series={[{ dataKey: 'nb_purchases', label: 'Nombre d’achats' }]}
          longXFormatter={(n) => `Valeur du panier : ${n} - ${n + 5} €`}
          title="Nombre d’achats par valeur du panier"
          loading={loading}
          interval={5}
        />
      </Card>
    </div>
  );

  const BasketSize = () => (
    <div>
      <ChartsOverviewLastYear
        component={DateChart}
        data={purchasedProductsByDay}
        series={[{ dataKey: 'avg_nb_items_per_purchase', label: 'Panier moyen (en volume)', reducer: avgItemsPerPurchaseReducer }]}
        title="Panier moyen (en volume)"
        loading={loading}
      />
      <Card title="Nombre d’achats par volume du panier">
        <NumericChart
          data={nbPurchasesPerNbProducts}
          xKey="nb_products"
          series={[{ dataKey: 'nb_purchases', label: 'Nombre d’achats' }]}
          longXFormatter={(n) => `${n} article${n > 1 ? 's' : ''}`}
          title="Nombre d’achats par volume du panier"
          loading={loading}
        />
      </Card>
    </div>
  );

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
        <div style={{ flex: 1, width: 200 }}>
          <Responsive minWidth={768}>
            <Tabs pages={pagesLargeScreen} />
          </Responsive>
          <Responsive maxWidth={767}>
            <Tabs pages={pagesSmallScreen} />
          </Responsive>
        </div>
        {storeFilter}
      </div>
      <Routes>
        <Route path="/volume" element={
          <ChartsOverviewLastYear
            component={MoneyDateChart}
            data={purchasesByDay}
            series={[{ dataKey: 'total_charged', label: 'Chiffre d’affaires', reducer: 'sum' }]}
            title="Chiffre d’affaires"
            loading={loading}
          />
        } />
        <Route path="/basket-volume" element={<BasketVolume />} />
        <Route path="/basket-size" element={<BasketSize />} />
      </Routes>
    </div>
  );
};
