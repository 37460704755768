import React from 'react';
import UserLink from '../User/UserLink';
import { List } from 'antd';
import _ from 'lodash';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
`;
const UserLine = ({ user }) => (
  <List.Item>
    <Link to={`/queuebusting/users/${user.id}`} style={{ display: 'block', flex: 1, color: 'inherit' }}>
      <LineContainer>
        <div style={{ fontWeight: 'bold' }}>
          <UserLink user={user} />
        </div>
      </LineContainer>
      <LineContainer>
        <div style={{ wordBreak: 'break-all' }}>
          {user.email}
        </div>
      </LineContainer>
    </Link>
  </List.Item>
);

export default ({ users }) => {
  return (
    <div>
      <List
        dataSource={_.orderBy(users, ['family_name', 'given_name'], ['asc', 'asc'])}
        renderItem={u => (<UserLine user={u} />)}
        size="small"
      />
    </div>
  );
};
