import React from 'react';
import { CreditCardOutlined, TabletFilled, AppleFilled, AndroidFilled, TabletOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

export default ({ platform, version }) => {
  if (version && version.match(/mpos/)) {
    return (
      <Tooltip title="Achat via PAX">
        <CreditCardOutlined style={{ color: 'rgb(24, 144, 255)' }} />
      </Tooltip>
    );
  }

  let icon = <TabletFilled style={{ color: '#EE5C57' }} />;
  if (platform === 'ios') {
    icon = <AppleFilled style={{ color: '#EE5C57' }}  />;
  } else if (platform === 'android') {
    icon = <AndroidFilled style={{ color: '#EE5C57' }} />;
  }

  if (version && version.match(/sdk/)) {
    return (
      <Tooltip title="Achat via Lyf Pay">
        {icon}
      </Tooltip>
    );
  }

  return <TabletOutlined />;
};
