import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from '../../hooks/withRouter';
import { SessionContext } from '../../context/session-context';
import { createSession } from '../../services/session';
import { Card, Spin, Input, Button, Alert, Form } from 'antd';
import './Invite.css';
import { updateBackofficeUser } from '../../services/api';
import { Link } from 'react-router-dom';
import { validatePasswordRules } from '../../utils/PasswordUtils';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const InviteUser = (props) => {
  const dispatch = useContext(SessionContext)[1];
  const [state, setState] = useState({
    loading: true,
    wrongLink: false,
    success: undefined,
    error: undefined,
    userId: undefined,
    loadingSave: false,
    password: undefined,
    password_confirmation: undefined,
    given_name: undefined,
    family_name: undefined,
  });

  useEffect(() => {
    createSession(props.params, dispatch)
      .then((user) => {
        setState({
          ...state,
          loading: false,
          userId: user.user.id,
        });
      }).catch(() => {
        setState({
          ...state,
          loading: false,
          wrongLink: true,
        });
      });
  }, []);

  const passwordFieldsNotEmpty = () => {
    return state.password || state.password_confirmation;
  };

  const canSubmit = () => {
    return state.given_name && state.family_name && canSubmitPassword();
  };

  const canSubmitPassword = () => {
    return validatePasswordRules(state.password, state.password_confirmation);
  };

  const onSubmit = (values) => {
    setState({
      ...state,
      success: undefined,
      error: undefined,
      loadingSave: true,
    });
    updateBackofficeUser(state.userId, values)
      .then(async () => {
        await createSession({
          email: props.params.email,
          password: state.password,
        }, dispatch);

        setState({
          ...state,
          success: true,
        });
      })
      .catch((error) => {
        if (error.response?.data?.error) {
          setState({
            ...state,
            error: error.response.data.error,
            loadingSave: false,
          });
        } else {
          setState({
            ...state,
            error: 'Impossible de créer votre compte.',
            loadingSave: false,
          });
        }
      });
  };

  const handleValuesChange = (values) => {
    setState({ ...state, ...values});
  };

  const actions = [];
  if (!state.loading && !state.wrongLink) {
    actions.push(
      <Button
        type="primary"
        htmlType="submit"
        key="1"
        disabled={!canSubmit()}
        loading={state.loadingSave}
      >
        Terminer la création de mon compte
      </Button>,
    );
  }

  return (
    <div className="InviteContainer">
      {state.success ? (
        <Alert
          type="success"
          message={
            <span>
              Votre compte a été créé avec succès. <Link to="/">Cliquez ici pour accéder au backoffice.</Link>
            </span>
          }
        />
      ) : (
        <Form onFinish={onSubmit} onValuesChange={handleValuesChange} className="InviteForm">
          <Card title="Lyf Pay | Backoffice" actions={actions}>
            {state.loading && (
              <div style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            )}
            {!state.loading && state.wrongLink && (
              <div>
                <Alert
                  message={
                    <span>
                      Le lien est incorrect ou a expiré. Vérifiez celui-ci, ou bien <Link to="/">connectez-vous</Link>
                      .
                    </span>
                  }
                  type="error"
                  showIcon
                />
              </div>
            )}
            {!state.loading && !state.wrongLink && (
              <>
                <p>
                  Vous avez été invité(e) à rejoindre le backoffice Lyf Pay. Remplissez simplement le formulaire
                  ci-dessous pour terminer la création de votre compte.
                </p>
                <Form.Item {...formItemLayout} name='given_name' label="Prénom">
                  <Input type="text" placeholder="Marie" />
                </Form.Item>
                <Form.Item {...formItemLayout} name='family_name' label="Nom">
                  <Input type="text" placeholder="Curie" />
                </Form.Item>
                <Form.Item {...formItemLayout} name='password' label="Mot de passe">
                  <Input type="password" placeholder="********" />
                </Form.Item>
                <Form.Item {...formItemLayout} name='password_confirmation' label="Confirmation du mot de passe" style={{ marginBottom: 0 }}>
                  <Input type="password" placeholder="********" />
                </Form.Item>
                {state.error && <Alert message={state.error} type="error" />}
                {passwordFieldsNotEmpty() && !canSubmitPassword() && (
                  <Alert
                    message={
                      <span>
                        Votre mot de passe doit contenir au moins dix caractères dont une majuscule, une minuscule, un
                        chiffre et un caractère spécial
                      </span>
                    }
                    type="info"
                  />
                )}
              </>
            )}
          </Card>
        </Form>
      )}
    </div>
  );
};

export default withRouter(InviteUser);
