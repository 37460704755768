import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import StoreStatus from './StoreStatus';

const columns = [
  {
    title: 'Statut',
    dataIndex: 'status',
  },
  {
    title: 'Nom',
    dataIndex: 'name',
  },
  {
    title: 'Partenaire',
    dataIndex: 'partnerName',
    sorter: (a, b) => {
      const partnerScore = a.partnerName.localeCompare(b.partnerName);
      if (partnerScore !== 0) {
        return partnerScore;
      }
      return a.rawName.localeCompare(b.rawName);
    },
    defaultSortOrder: 'ascend',
  },
];

const StoresTable = ({ stores, loading, onSelectStore, ...props }) => {
  const data = stores.map(store => ({
    id: store.id,
    key: store.id,
    rawName: store.name,
    name: (
      <div>
        <b>{store.name}</b><br />
        <small>{store.address}</small>
      </div>
    ),
    partnerName: store.partner.name,
    status: (<StoreStatus status={store.status} />),
  }));

  return (
    <Table
      dataSource={data}
      columns={columns}
      loading={loading}
      {...props}
      onRow={(store) => {
        return {
          onClick: () => onSelectStore(store),
          style: {
            cursor: 'pointer',
          },
        };
      }}
    />
  );
};

StoresTable.props = {
  stores: PropTypes.arrayOf(PropTypes.objectOf({
    id: PropTypes.string,
    name: PropTypes.string,
    partner: PropTypes.objectOf({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  })),
  loading: PropTypes.bool,
  size: PropTypes.string,
};

export default StoresTable;
