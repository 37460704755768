import React, { useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Divider, Form } from 'antd';
import moment from 'moment';
import SelectStores from '../../components/SelectStore/SelectStores';
import { exportPurchases } from '../../services/api';
import { saveAs } from 'file-saver';
import Responsive from 'react-responsive';
import styled from 'styled-components';

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const SearchInputs = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const Export = () => {
  const [state, setState] = useState({
    downloading: false,
    minDate: moment().subtract('2', 'weeks'),
    maxDate: moment().subtract('1', 'day'),
  });

  const exportPurchasesExport = () => {
    setState({ ...state, downloading: true });

    const minDate = state.minDate.format('YYYY-MM-DD');
    const maxDate = state.maxDate.format('YYYY-MM-DD');
    const storesIds = state.storesIds;
    const partnersIds = state.partnersIds;

    exportPurchases(minDate, maxDate, storesIds, partnersIds).then((data) => {
      setState({ ...state, downloading: false });
      const blob = new Blob([JSON.stringify(data, null, 2)], { type : 'application/json;charset=utf-8' });
      saveAs(blob, `lyf-pay-export_${minDate}_${maxDate}.json`);
    });
  };

  const onSelect = (newStoresIds, newPartnersIds) => {
    setState({ ...state, storesIds: newStoresIds, partnersIds: newPartnersIds });
  };

  const onValuesChange = ({ minDate, maxDate }) => {
    if (minDate !== undefined)
      setState({ ...state, minDate });
    if (maxDate !== undefined)
      setState({ ...state, maxDate });
  };

  return (
    <div>
      <Divider><h3>Export des achats</h3></Divider>
      <p>
        Cet outil permet d’exporter au format brut toutes les ventes réalisées dans la période et pour les magasins sélectionnés.
      </p>
      <Form onValuesChange={onValuesChange}>
        <Form.Item {...formItemLayout} label="Magasins">
          <SearchInputs>
            <div style={{ width:'100%'}}>
              <Responsive minWidth={768}>
                <SelectStores
                  placeholder="Tous"
                  selectedStores={state.storesIds}
                  selectedPartners={state.partnersIds}
                  onChange={onSelect}
                  multiple
                />
              </Responsive>
              <Responsive maxWidth={767}>
                <SelectStores
                  placeholder="Tous"
                  selectedStores={state.storesIds}
                  selectedPartners={state.partnersIds}
                  onChange={onSelect}
                  style={{ width: 'calc(100%)' }}
                  multiple
                />
              </Responsive>
            </div>
          </SearchInputs>
        </Form.Item>
        <Form.Item {...formItemLayout} name='minDate' label="Date de début" initialValue={moment().subtract('2', 'weeks')}>
          <DatePicker style={{ width: 200 }} format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item {...formItemLayout} name='maxDate' label="Date de fin" initialValue={moment().subtract('1', 'day')}>
          <DatePicker style={{ width: 200 }} format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 12, offset: 6 }}
        >
          <Button
            type="primary"
            htmlType="submit"
            disabled={state.downloading || !state.minDate || !state.maxDate}
            onClick={() => exportPurchasesExport()}
            icon={<DownloadOutlined />}
            loading={state.downloading}
          >
            Télécharger les données
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Export;
