import React, { useState }  from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, LineChart, Line, CartesianGrid, Legend, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import NoData from './NoData';
import Loading from './Loading';
import { COLORS } from '../../config';
import { parse } from 'json2csv';
import _ from 'lodash';
import moment from 'moment';

const Export = (props) => {
  const [state, setState] = useState({
    hover: false,
    exporting: false,
    exported: false,
  });

  const onMouseEnter = () => {
    setState({ ...state, hover: true });
  };
  const onMouseLeave = () => {
    setState({ ...state, hover: false });
  };
  const onClick = () => {
    if (!state.exporting && !state.exported) {
      setState({
        ...state,
        exporting: true,
      });
      setTimeout(() => {
        const fields = [
          props.xKey,
        ].concat(props.series.map((serie) => {
          return {
            label: _.snakeCase(serie.label),
            value: serie.dataKey,
          };
        }));

        const csv = parse(props.data, { fields });

        setState({
          ...state,
          exporting: false,
          exported: true,
          downloadData: `data:text/csv;base64,${btoa(csv)}`,
        });
      }, 500);
    }
  };

  const opacity = (state.hover || state.exported) ? 1 : 0.6;
  const cursor = state.exporting ? 'default' : 'pointer';
  const style = {
    backgroundColor: 'white',
    padding: 4,
    borderRadius: 4,
    display: 'inline-block',
    fontSize: '10px',
    position: 'absolute',
    right: 8,
    zIndex: 9,
    opacity,
    cursor,
    color: 'black',
    textDecoration: 'none',
  };

  let content = 'Exporter';
  let download = false;
  if (state.exporting) {
    content = 'Création de l’export...';
  } else if (state.exported) {
    content = 'Télécharger';
    download = `export_${_.kebabCase(props.title)}_${moment().format('YYYY-MM-DD_HH-mm')}.csv`;
  }

  return (
    <a
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      href={state.downloadData}
      download={download}
    >
      {content}
    </a>
  );
};

const AppBarChart = ({ data, xKey, shortXFormatter, longXFormatter, series, unit, title, loading, yDomain, type = 'bar', ...props }) => {
  if (loading) {
    return (<Loading />);
  }
  if (!data || data.length === 0) {
    return (<NoData />);
  }

  let valueFormatter = (v) => v.toLocaleString();
  if (unit !== undefined) {
    valueFormatter = (v) => `${v.toLocaleString()}${'\u00A0'}${unit}`;
  }
  valueFormatter = props.valueFormatter || valueFormatter;

  let Chart = BarChart;
  let ChartData = Bar;
  let dataProps = {};
  if (type === 'line') {
    Chart = LineChart;
    ChartData = Line;
    dataProps = { isAnimationActive: false, type: 'monotone' };
  }

  return (
    <div style={{ position: 'relative' }}>
      <Export
        data={data}
        series={series}
        xKey={xKey}
        title={title}
      />
      <ResponsiveContainer width="100%" height={props.height || 300}>
        <Chart data={data} margin={{ top: 16, left: 0, right: 16, bottom: 0 }}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey={xKey} tickFormatter={shortXFormatter} />
          <YAxis tickFormatter={valueFormatter} domain={yDomain} width={70} axisLine={false} tickLine={false} />
          <Tooltip
            labelFormatter={longXFormatter}
            formatter={valueFormatter}
            cursor={{ opacity: 0.5 }}
            labelStyle={{
              fontWeight: 'bold',
            }}
          />
          <Legend />
          {series.map((serie, i) => {
            let color = (COLORS[i % COLORS.length]);
            if (serie.color !== undefined) {
              if (typeof serie.color === 'number') {
                color = (COLORS[serie.color % COLORS.length]);
              } else {
                color = serie.color;
              }
            }
            return (
              <ChartData key={serie.dataKey} dataKey={serie.dataKey} fill={color} stroke={color} name={serie.label} stackId={serie.stackId} {...dataProps} />
            );
          })}
        </Chart>
      </ResponsiveContainer>
    </div>
  );
};

AppBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  xKey: PropTypes.string.isRequired,
  series: PropTypes.arrayOf(PropTypes.shape({
    dataKey: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stackId: PropTypes.string,
  })),
  shortXFormatter: PropTypes.func,
  longXFormatter: PropTypes.func,
  valueFormatter: PropTypes.func,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  height: PropTypes.number,
  type: PropTypes.oneOf(['bar', 'line']),
  yDomain: PropTypes.arrayOf(PropTypes.number),
};

export default AppBarChart;
