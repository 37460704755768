import React, { useState } from 'react';
import { Button } from 'antd';
import { CheckOutlined, ReloadOutlined } from '@ant-design/icons';
import { inviteBackofficeUser } from '../../services/api';

const SendInviteButton = ({ user }) => {
  const [status, setStatus] = useState('');

  const sendInvite = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setStatus('sending');
    await inviteBackofficeUser(user.id);
    setStatus('sent');
  };

  const label = status === 'sent' ? 'Invitation envoyée !' : 'Renvoyer l’invitation';
  const icon = status === 'sent' ? <CheckOutlined /> : <ReloadOutlined />;

  return (
    <Button
      size="small"
      onClick={sendInvite}
      loading={status === 'sending'}
      icon={icon}
      disabled={status === 'sent'}
    >
      {label}
    </Button>
  );
};

export default SendInviteButton;
