import React from 'react';
import PropTypes from 'prop-types';

const UserLink = ({ user }) => {
  return (
    <span>{user.given_name} {user.family_name}</span>
  );
};

UserLink.propTypes = {
  user: PropTypes.shape({
    given_name: PropTypes.string.isRequired,
    family_name: PropTypes.string.isRequired,
  }),
};

export default UserLink;
