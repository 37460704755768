import React from 'react';
import { withRouter } from '../../hooks/withRouter';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import _ from 'lodash';

const Tabs = withRouter(({ navigate, location, pages }) => {
  const items = pages.map(({label, url}) => ({key: url, label}));

  return (
    <div style={{ paddingBottom: '0.75rem' }}>
      <Menu
        defaultSelectedKeys={[_.last(location.pathname.split('/'))]}
        mode="horizontal"
        onClick={({ key }) => navigate(key)}
        style={{
          background: 'none',
        }}
        items={items}
      />
    </div>
  );
});

Tabs.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
};

export default Tabs;
