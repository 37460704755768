import React from 'react';
import Tabs from '../../components/Tabs/Tabs';
import { Route, Routes } from 'react-router-dom';
import DateChart from '../../components/DateChart/DateChart';
import ChartsOverviewLastYear from '../../components/ChartsOverviewLastYear/ChartsOverviewLastYear';
import moment from 'moment';
import { Card, Col, Row } from 'antd';

export default ({ purchasesByDay, purchasesByWeek, purchasesByMonth, usersStats, loading, storeFilter }) => {
  const pages = [
    { url: 'sales', label: 'Paiements' },
    { url: 'users', label: 'Utilisateurs actifs' },
    { url: 'new-active-users', label: 'Nouveaux utilisateurs actifs' },
  ];

  if (usersStats.newUsersByDay) {
    pages.push({ url: 'new-users', label: 'Nouveaux utilisateurs' });
  }

  const NewActiveUser = () => (
    <>
      <ChartsOverviewLastYear
        component={DateChart}
        data={usersStats.newActiveUsersByDay}
        series={[{ dataKey: 'nb_users', label: 'Nombre de nouveaux utilisateurs actifs', reducer: 'sum' }]}
        title="Nouveaux utilisateurs actifs"
        loading={loading}
      />
      <p style={{ textAlign: 'center', marginTop: '0.75rem' }}>
        La date prise en compte est la date du premier achat des utilisateurs dans un des magasins sélectionnés.
      </p>
    </>
  );

  const NewUsers = () => (
    <>
      <ChartsOverviewLastYear
        component={DateChart}
        data={usersStats.newUsersByDay}
        series={[{ dataKey: 'nb_users', label: 'Nombre de nouveaux utilisateurs', reducer: 'sum' }]}
        title="Nouveaux utilisateurs"
        loading={loading}
      />
      <p style={{ textAlign: 'center', marginTop: '0.75rem' }}>
        Nouveaux comptes créés, sans prendre les achats en compte.
      </p>
    </>
  );

  const Users = () => (
    <div>
      <Row gutter={16}>
        <Col span={24}>
          <Card title="Jour par jour" style={{ marginBottom: 16 }}>
            <DateChart
              data={purchasesByDay}
              series={[{ dataKey: 'nb_users', label: 'Nombre d’utilisateurs actifs', reducer: 'sum' }]}
              minDate={moment().subtract(2, 'months').toISOString()}
              maxDate={moment()}
              title="Nombre d’utilisateurs actifs (par jour)"
              loading={loading}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card title="Semaine par semaine" style={{ marginBottom: 16 }}>
            <DateChart
              data={purchasesByWeek}
              series={[{ dataKey: 'nb_users', label: 'Nombre d’utilisateurs actifs', reducer: 'sum' }]}
              minDate={moment().subtract(4, 'months').toISOString()}
              maxDate={moment()}
              aggBy="week"
              title="Nombre d’utilisateurs actifs (par semaine)"
              loading={loading}
            />
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card title="Mois par mois" style={{ marginBottom: 16 }}>
            <DateChart
              data={purchasesByMonth}
              series={[{ dataKey: 'nb_users', label: 'Nombre d’utilisateurs actifs', reducer: 'sum' }]}
              minDate={moment().subtract(1, 'year').toISOString()}
              maxDate={moment()}
              aggBy="month"
              title="Nombre d’utilisateurs actifs (par mois)"
              loading={loading}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
        <div style={{ flex: 1, width: 200 }}>
          <Tabs pages={pages} />
        </div>
        {storeFilter}
      </div>
      <Routes>
        <Route path="/sales" element={
          <ChartsOverviewLastYear
            component={DateChart}
            data={purchasesByDay}
            series={[{ dataKey: 'nb_charged', label: 'Nombre d’achats', reducer: 'sum' }]}
            title="Paiements"
            loading={loading}
          />} />
        <Route path="/new-active-users" element={<NewActiveUser />} />
        <Route path="/new-users" element={<NewUsers />} />
        <Route path="/users" element={<Users />} />
      </Routes>
    </div>
  );
};
