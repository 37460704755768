import React, { useState, useContext, useEffect } from 'react';
import { SessionContext } from '../../context/session-context';
import SelectStore from '../../components/SelectStore/SelectStore';
import { getMissingBarcodes } from '../../services/api';
import MissingBarcodesTable from '../../components/MissingBarcodes/MissingBarcodesTable';
import MissingBarcodesList from '../../components/MissingBarcodes/MissingBarcodesList';
import { Checkbox } from 'antd';
import Responsive from 'react-responsive';

export default () => {
  const [session] = useContext(SessionContext);
  const [state, setState] = useState({
    missingBarcodes: [],
    notReferencedOnly: true,
    loading: true,
  });

  const storeChange = async (storeId) => {
    setState({ ...state, oading: true });
    const missingBarcodes = await getMissingBarcodes(storeId);
    setState({
      ...state,
      missingBarcodes,
      loading: false,
    });
  };

  useEffect(() => {
    storeChange(session.user.all_stores[0].id);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Responsive minWidth={768}>
          Magasin{'\u00A0'}: <SelectStore stores={session.user.all_stores} onChange={storeChange} allOption={false} style={{ width: '30vw' }} />
        </Responsive>
        <Responsive maxWidth={767}>
          <SelectStore stores={session.user.all_stores} onChange={storeChange} allOption={false} style={{ width: '100%' }} />
        </Responsive>

        <Responsive minWidth={768}>
          {(largeScreen) => (
            <Checkbox
              checked={state.notReferencedOnly}
              onChange={(e) => setState({ ...state, notReferencedOnly: e.target.checked })}
              style={{
                marginLeft: largeScreen ? '1em' : 0,
              }}
            >
              Codes-barres non référencés seulement
            </Checkbox>
          )}
        </Responsive>
      </div>

      <Responsive minWidth={768}>
        <MissingBarcodesTable
          loading={state.loading}
          barcodes={state.missingBarcodes}
          notReferencedOnly={state.notReferencedOnly}
        />
      </Responsive>
      <Responsive maxWidth={767}>
        <MissingBarcodesList
          loading={state.loading}
          barcodes={state.missingBarcodes}
          notReferencedOnly={state.notReferencedOnly}
        />
      </Responsive>
    </div>
  );
};
