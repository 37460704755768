import React, { useState, useEffect } from 'react';
import { getUsersStatsFrequency } from '../../services/api';
import Tabs from '../../components/Tabs/Tabs';
import { Route, Routes } from 'react-router-dom';
import NumericChart from '../../components/BarChart/NumericChart';
import DateChart from '../../components/DateChart/DateChart';
import _ from 'lodash';
import { Card, DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const pages = [
  { url: 'sharing', label: 'Répartition' },
  { url: 'frequency', label: 'Fréquence d’utilisation' },
];

const StatsFrequency = ({ storesIds, partnersIds }) => {
  const [frequencyStats, setFrequencyStats] = useState([]);
  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getUsersStatsFrequency(storesIds, partnersIds, dates[0], dates[1]).then((data) => {
      const stats = _.sortBy(data.stats_frequency, 'nb_purchases');
      setFrequencyStats(stats);
      setLoading(false);
    });
  }, [storesIds, partnersIds, dates]);

  return (
    <Card extra={<RangePicker onChange={(_d, d) => setDates(d)} />}>
      <NumericChart
        data={frequencyStats}
        xKey="nb_purchases"
        series={[{ dataKey: 'nb_users', label: 'Nombre d’utilisateurs' }]}
        longXFormatter={(n) => `${n} achat${n > 1 ? 's' : ''}`}
        title="Fréquence d’achat"
        loading={loading}
      />
    </Card>
  );
};

export default ({ loyaltyStats, loading, storeFilter, storesIds, partnersIds }) => {
  const loyaltyData = loyaltyStats.map((stat) => {
    return {
      ...stat,
      percent_users_without_loyalty: (stat.nb_users - stat.nb_users_with_loyalty - stat.nb_corporate_users) / stat.nb_users * 100,
      percent_users_with_loyalty: stat.nb_users_with_loyalty / stat.nb_users * 100,
      percent_corporate_users: stat.nb_corporate_users / stat.nb_users * 100,
    };
  });

  const Sharing = () => (
    <Card>
      <DateChart
        data={loyaltyData}
        series={[
          { dataKey: 'percent_users_without_loyalty', label: '% Utilisateurs non fidélisés', reducer: 'sum', stackId: 'a' },
          { dataKey: 'percent_users_with_loyalty', label: '% Utilisateurs fidélisés', reducer: 'sum', stackId: 'a' },
          { dataKey: 'percent_corporate_users', label: '% Membres du personnel', reducer: 'sum', stackId: 'a' },
        ]}
        aggBy="month"
        unit="%"
        title="Répartition fidélisation"
        loading={loading}
        yDomain={[0, 100]}
      />
    </Card>
  );

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
        <div style={{ flex: 1, width: 200 }}>
          <Tabs pages={pages} />
        </div>
        {storeFilter}
      </div>
      <Routes>
        <Route path="/sharing" element={<Sharing />} />
        <Route path="/frequency" element={
          <StatsFrequency storesIds={storesIds} partnersIds={partnersIds} />
        } />
      </Routes>
    </div>
  );
};
