import React from 'react';
import PropTypes from 'prop-types';

const Price = ({ cents, currency, round = false }) => {
  let currencyBefore = '', currencyAfter = '';
  if (currency === 'EUR') {
    currencyAfter = '\u00A0' + '€';
  } else if (currency === 'USD') {
    currencyBefore = '$';
  }
  const price = round ? Math.floor(cents/100).toLocaleString() : (cents/100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return (
    <span>{`${currencyBefore}${price}${currencyAfter}`}</span>
  );
};

Price.propTypes = {
  cents: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};

export default Price;
