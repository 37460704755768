import React from 'react';
import { Table, Tag } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import UserLink from '../User/UserLink';

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
  },
  {
    title: 'Vigile',
    dataIndex: 'backofficeUser',
  },
  {
    title: 'Score',
    dataIndex: 'score',
  },
  {
    title: 'Stratégie',
    dataIndex: 'strategy',
  },
  {
    title: 'Scan',
    dataIndex: 'scan',
  },
  {
    title: 'Message',
    dataIndex: 'danger',
  },
];

const strategyMapping = {
  none_verif: { text: 'Pas de vérification' },
  partial_verif: { color: 'blue', text: 'Vérification partielle' },
  total_verif: { color: 'yellow', text: 'Vérification totale' },
  blocked_verif: { color: 'red', text: 'Vérification bloquée' },
  quick_verif: { color: 'cyan', text: 'Vérification visuelle' },
};
const Strategy = ({ strategy }) => {
  const { color, text } = strategyMapping[strategy];
  return (
    <Tag color={color}>{text}</Tag>
  );
};

const scanMapping = {
  [true]: { color: 'green', text: 'Autorisé' },
  [false]: { color: 'red', text: 'Bloqué' },
};
const Scan = ({ canScan }) => {
  const { color, text } = scanMapping[canScan];
  return (
    <Tag color={color}>{text}</Tag>
  );
};

const VerificationHistory = ({ history }) => {
  const items = _.orderBy(history, ['date'], ['desc']).map((hist) => {
    return {
      key: hist.id,
      date: moment(hist.date).format('LLLL'),
      backofficeUser: (<UserLink user={hist.gatekeeper} />),
      score: hist.score,
      danger: (<div style={{ maxWidth: 300 }}>{hist.danger}</div>),
      strategy: (<Strategy strategy={hist.strategy} />),
      scan: (<Scan canScan={hist.can_scan} />),
    };
  });

  return (
    <Table
      title={() => (<div style={{ fontWeight: 'bold', textAlign: 'center' }}>Scans du reçu</div>)}
      bordered
      dataSource={items}
      columns={columns}
      pagination={false}
    />
  );
};

export default VerificationHistory;
