import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

const VerificationStatus = ({ status, verification_is_valid, scanned }) => {
  if (!status) {
    if (verification_is_valid === true) {
      status = 'ok';
    } else if (verification_is_valid === false) {
      status = 'ko';
    } else if (scanned) {
      status = 'scanned';
    }
  }
  let color;
  let text = 'Non contrôlé';
  if (status === 'ok') {
    color = 'green';
    text = 'Validé';
  } else if (status === 'ko') {
    color = 'red';
    text = 'Fraude';
  } else if (status === 'scanned') {
    color = 'blue';
    text = 'Scanné';
  }
  return (
    <Tag color={color}>{text}</Tag>
  );
};

VerificationStatus.propTypes = {
  status: PropTypes.string,
  verification_is_valid: PropTypes.bool,
  scanned: PropTypes.bool,
};

export default VerificationStatus;
