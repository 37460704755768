import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from '../../hooks/withRouter';
import { SessionContext } from '../../context/session-context';
import { getPurchasesReport } from '../../services/api';
import moment from 'moment';
import _ from 'lodash';
import { Alert, Divider, Radio, Spin, Table } from 'antd';
import Price from '../../components/Price/Price';
import Barcode from 'react-barcode';
import { StatsRow, Stat } from '../../components/Dashboard/Stats';
import './DailyReport.css';

const TYPE_AGGREGATED = 'TYPE_AGGREGATED';
const TYPE_BY_PURCHASE = 'TYPE_BY_PURCHASE';

const columns = [
  {
    title: 'Article',
    dataIndex: 'name',
  },
  {
    title: 'Quantité',
    dataIndex: 'quantity',
  },
  {
    title: 'Prix total TTC',
    dataIndex: 'totalPrice',
  },
  {
    title: 'Code-barre',
    dataIndex: 'barcodes',
  },
];

const PurchaseReport = ({ short_identifier, items, created_at }) => {
  const data = items.map((item, i) => {
    return {
      key: i,
      name: item.name,
      quantity: (<b>x{item.quantity}</b>),
      totalPrice: (<Price cents={item.quantity * item.unit_price.cents} currency={item.unit_price.currency} />),
      barcodes: (<div>
        {[...Array(item.quantity)].map((_n, i) => (
          <Barcode key={i} value={item.barcode_value} format={item.barcode_type} height={50} width={1} />
        ))}
      </div>),
    };
  });
  return (
    <div style={{
      paddingBottom: '1.5em',
      paddingLeft: '1.5em',
      paddingRight: '1.5em',
      marginBottom: '1.5em',
      borderBottom: '5px dotted black',
      pageBreakInside: 'avoid',
    }}>
      <Divider>{short_identifier} à {moment(created_at).format('HH:mm:ss')}</Divider>

      <Table size="middle" columns={columns} dataSource={data} pagination={false} style={{ marginTop: 0 }} />
    </div>
  );
};

const AggregatedReport = ({ purchases }) => {
  const articles = {};
  purchases.forEach((purchase) => {
    purchase.items.forEach((item) => {
      if (!articles[item.barcode_value]) {
        articles[item.barcode_value] = {...item};
      } else {
        articles[item.barcode_value].quantity += item.quantity;
      }
    });
  });

  const data = _.sortBy(Object.keys(articles).map((barcodeValue) => {
    const article = articles[barcodeValue];
    const quantityStyle = {};
    if (article.quantity > 1) {
      quantityStyle.color ='#1890ff';
    }
    return {
      key: barcodeValue,
      qty: article.quantity,
      name: article.name,
      quantity: (<b style={quantityStyle}>x{article.quantity}</b>),
      totalPrice: (<Price cents={article.quantity * article.unit_price.cents} currency={article.unit_price.currency} />),
      barcodes: (<Barcode value={article.barcode_value} format={article.barcode_type} height={50} width={2} />),
    };
  }), 'qty');

  return (
    <div>
      <Table size="middle" columns={columns} dataSource={data} pagination={false} />
    </div>
  );
};

export default withRouter((props) => {
  const [session] = useContext(SessionContext);
  const [state, setState] = useState({
    purchases: [],
    loading: false,
    totalPrice: 0,
    currency: 'EUR',
    type: TYPE_AGGREGATED,
    nbItems: 0,
  });

  const loadPurchases = async () => {
    setState({ ...state, loading: true });
    const params = props.params;
    try {
      const purchases = await getPurchasesReport(params);
      const nbItems = _.sumBy(purchases, (purchase) => _.sumBy(purchase.items, 'quantity'));
      const totalPrice = _.sumBy(purchases, (purchase) => purchase.total_price.cents);
      let currency = 'EUR';
      if (purchases.length > 0) {
        currency = purchases[0].total_price.currency;
      }
      setState({ ...state,
        purchases,
        loading: false,
        nbItems,
        totalPrice,
        currency,
      });
    } catch(error) {
      setState({ ...state, loading: false, error });
    }
  };

  useEffect(() => {
    const store = session.user.all_stores.find((store) => store.id === props.params.storeId).name;
    const date = moment(props.params.date, 'YYYY-MM-DD').format('dddd D MMMM YYYY');

    setState({ ...state, store, date });
    loadPurchases();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="DailyReport">
      <h1 className="Report-Title">Rapport journalier</h1>
      <h2 className="Report-Store">{state.store}</h2>
      <h3 className="Report-Date">{state.date}</h3>

      {state.loading && (<div style={{ textAlign: 'center', marginTop: 100 }}><Spin size="large" /></div>)}

      {state.error && (
        <Alert
          message={`Impossible de charger les données du rapport journalier. Veuillez contacter le support (support@neos.app) en leur communiquant l’erreur (${state.error.message}).`}
          type="error"
          style={{ marginBottom: 16 }}
        />
      )}

      {!state.loading && !state.error && (
        <React.Fragment>
          <StatsRow>
            <Stat title="Achats" value={state.purchases.length.toFixed(0)} />
            <Stat title="Articles" value={state.nbItems.toFixed(0)} />
            <Stat title="Chiffre d’affaires" value={(<Price cents={state.totalPrice} currency={state.currency} />)} />
          </StatsRow>

          <Divider>
            <Radio.Group
              defaultValue={TYPE_AGGREGATED}
              buttonStyle="solid"
              onChange={({ target }) => setState({ ...state, type: target.value })}
            >
              <Radio.Button value={TYPE_AGGREGATED}>Codes-barres aggrégés par article</Radio.Button>
              <Radio.Button value={TYPE_BY_PURCHASE}>Tickets détaillés</Radio.Button>
            </Radio.Group>
          </Divider>

          {state.type === TYPE_BY_PURCHASE && state.purchases.map((purchase) => (
            <PurchaseReport key={purchase.id} {...purchase} />
          ))}

          {state.type === TYPE_AGGREGATED && (
            <AggregatedReport purchases={state.purchases} />
          )}
        </React.Fragment>
      )}
    </div>
  );
});
