import React, { useState } from 'react';
import { UserAddOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import Responsive from 'react-responsive';
import { Link } from 'react-router-dom';
import SelectStore from '../../components/SelectStore/SelectStoreAllUserStores';
import { getAppUsers } from '../../services/api';
import UsersTable from '../../components/Queuebuster/UsersTable';
import UsersList from '../../components/Queuebuster/UsersList';

const { Title } = Typography;

export default () => {
  const [users, setUsers] = useState([]);

  const onChange = async (storeId) => {
    setUsers(await getAppUsers(storeId));
  };

  return (
    <div>
      <Title>Queuebusters</Title>
      <Responsive minWidth={768}>
        {(largeScreen) => (
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
            <div style={!largeScreen ? { flex: 1, width: 200, paddingRight: 16 } : null}>
              {largeScreen && (<span>Magasin{'\u00A0'}: </span>)}
              <SelectStore
                onChange={onChange}
                allOption={false}
                style={!largeScreen ? { width: '100%' } : { width: '30vw' }}
              />
            </div>
            <div>
              <Link to="/queuebusting/users/new">
                <Button icon={<UserAddOutlined />}>{largeScreen && 'Créer un queuebuster'}</Button>
              </Link>
            </div>
          </div>
        )}
      </Responsive>
      <Responsive minWidth={768}>
        <UsersTable users={users} />
      </Responsive>
      <Responsive maxWidth={767}>
        <UsersList users={users} />
      </Responsive>
    </div>
  );
};
