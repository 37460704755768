import React, { useState } from 'react';
import { Input, Row, Col, Button, Alert, Form } from 'antd';
import SelectStore from '../../components/SelectStore/SelectStoreAllUserStores';
import { createAppUser } from '../../services/api';
import { Link } from 'react-router-dom';
import { validatePasswordRules } from '../../utils/PasswordUtils';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const btnItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

// https://github.com/react-component/form/issues/287
const SelectStoreComponent = (props) => {
  return <SelectStore {...props} />;
};

const CreateUser = () => {
  const [form] = Form.useForm();
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState();
  const [family_name, setFamilyName] = useState();
  const [given_name, setGivenName] = useState();
  const [email, setEmail] = useState('');
  const [successConfirmation, setSuccessConfirmation] = useState();
  const [password, setPassword] = useState();
  const [password_confirmation, setPasswordConfirmation] = useState();
  const [stores, setStores] = useState([]);

  const handleValuesChange = ({ email: newEmail, password_confirmation, password, family_name, given_name, stores }) => {
    if (newEmail !== undefined) {
      if (newEmail && newEmail !== email && Math.abs(newEmail.length - (email || '').length) > 10) {
        const emailRegex =
          /(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})/gim;
        const m = newEmail.match(emailRegex);
        if (m && m.length > 0 && m[0] !== newEmail) {
          newEmail = m[0];
        }
      }
      setEmail(newEmail);
    }
    if (password)
      setPassword(password);
    if (password_confirmation)
      setPasswordConfirmation(password_confirmation);
    if (family_name)
      setFamilyName(family_name);
    if (given_name)
      setGivenName(given_name);
    if (stores)
      setStores(stores);
  };

  const handleSubmit = ({ given_name, family_name, stores, password, password_confirmation }) => {
    setError();
    setSuccessConfirmation(false);
    setCreating(true);

    const fields = {};
    fields.given_name = given_name;
    fields.family_name = family_name;
    fields.email = email.toLocaleLowerCase();
    fields.password = password;
    fields.password_confirmation = password_confirmation;
    fields.stores = stores || [];
    createAppUser(fields)
      .then(() => {
        setSuccessConfirmation(true);
        setEmail('');
        setPassword();
        setPasswordConfirmation();
        form.resetFields();
        setCreating(false);
      })
      .catch((error) => {
        setSuccessConfirmation(false);
        setCreating(false);

        if (error.response?.data) {
          setError(error.response.data.error);
        } else {
          setError('Impossible de créer le queuebuster');
        }
      });
  };

  const passwordFieldsNotEmpty = () => {
    return password || password_confirmation;
  };

  const canSubmit = () => {
    const fields = { email, given_name, family_name, password, password_confirmation, stores };

    if (fields.email.length < 5 || !fields.stores || fields.stores.length === 0) {
      return false;
    }

    if (!fields.given_name || !fields.family_name || !fields.password || !fields.password_confirmation) {
      return false;
    }

    return canSubmitPassword();
  };

  const canSubmitPassword = () => {
    return validatePasswordRules(password, password_confirmation);
  };

  return (
    <div>
      <h2 style={{ textAlign: 'center' }}>Créer un queuebuster</h2>
      <Row>
        <Col md={16} xs={24}>
          <Form onFinish={handleSubmit} onValuesChange={handleValuesChange}>
            <Form.Item {...formItemLayout} name='email' label="Adresse email" initialValue={email}>
              <Input
                type="email"
                placeholder="marie.curie@gmail.com"
              />
            </Form.Item>
            <Form.Item {...formItemLayout} name='stores' label="Magasins accessibles">
              <SelectStoreComponent allOption={false} multiple={true} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item {...formItemLayout} name='given_name' label="Prénom">
              <Input type="text" placeholder="Marie" />
            </Form.Item>
            <Form.Item {...formItemLayout} name='family_name' label="Nom">
              <Input type="text" placeholder="Curie" />
            </Form.Item>
            <Form.Item {...formItemLayout} name='password' label="Mot de passe">
              <Input type="password" placeholder="********" />
            </Form.Item>
            <Form.Item {...formItemLayout} name='password_confirmation' label="Confirmation du mot de passe">
              <Input type="password" placeholder="********" />
            </Form.Item>
            {error && <Alert message={error} type="error" />}

            {passwordFieldsNotEmpty() && !canSubmitPassword() && (
              <Alert
                message={
                  <span>
                    Votre mot de passe doit contenir au moins dix caractères dont une majuscule, une minuscule, un
                    chiffre et un caractère spécial
                  </span>
                }
                type="info"
              />
            )}
            {successConfirmation && (
              <Alert
                message={
                  <span>
                    {'Le queuebuster a bien été créé.'}{' '}
                    <Link to="/queuebusting">Retour à la liste des queuebusters</Link>
                  </span>
                }
                type="success"
              />
            )}
            <Form.Item {...btnItemLayout}>
              <Button type="primary" htmlType="submit" disabled={!canSubmit()} loading={creating}>
                Créer le queuebuster
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default CreateUser;
