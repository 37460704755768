import React from 'react';
import { Row, Col, List } from 'antd';
import { DashboardOutlined, UserOutlined, EuroOutlined} from '@ant-design/icons';
import Price from '../Price/Price';
import { StatsRow, Stat, SubStat } from '../Dashboard/Stats';
import Card from '../Card/Card';

const MobileList = ({ items }) => (
  <List
    style={{ marginTop: -8, marginBottom: -8 }}
    size="small"
    dataSource={items}
    renderItem={({ title, value }) => (
      <List.Item>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div>
            {title}
          </div>
          <div style={{ fontWeight: 'bold' }}>
            {value}
          </div>
        </div>
      </List.Item>
    )}
  />
);

const StoresStats = ({ loading, stats, mobile }) => {
  const columnWidthMd = (stats && stats.wallets_credits_cents) ? 8 : 12;
  const loaded = stats && stats.currency;

  const sales = {};
  const users = {};
  const wallets = {};
  if (loaded) {
    sales.volume = (<Price cents={stats.total_charged} currency={stats.currency} round />);
    sales.purchasesCount = (stats.nb_purchases.toLocaleString());
    sales.avgBasketValue = (<Price cents={stats.avg_charged} currency={stats.currency} />);
    sales.avgBasketVolume = stats.avg_nb_items_per_purchase.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    users.activeCount = stats.nb_users.toLocaleString();
    users.loyalCount = Math.round(stats.nb_users_loyal / stats.nb_users * 100) + '\u00A0%';
    users.employesCount = Math.round(stats.nb_users_corporate / stats.nb_users * 100) + '\u00A0%';
    if (stats.nb_users_global !== undefined) {
      users.totalCount = stats.nb_users_global.toLocaleString();
    }

    if (stats.wallets_credits_cents) {
      wallets.credited = (<Price cents={stats.wallets_credits_cents} currency={stats.currency} round />);
      wallets.available = (<Price cents={stats.wallets_balance_cents} currency={stats.currency} round />);
      wallets.transfered = (<Price cents={stats.wallets_payouts_cents} currency={stats.currency} round />);
    }
  }

  return (
    <Row gutter={16}>
      <Col md={columnWidthMd} xs={24}>
        <Card
          title="Ventes"
          icon={<DashboardOutlined style={{ float: 'right', fontSize: 20 }} />}
          loading={loading}
          style={{ marginBottom: 16 }}
        >
          {loaded && (mobile ? (
            <MobileList
              items={[
                { title: 'Chiffre d’affaires', value: sales.volume },
                { title: 'Nombre d’achats', value: sales.purchasesCount },
                { title: 'Panier moyen (valeur)', value: sales.avgBasketValue },
                { title: 'Panier moyen (volume)', value: sales.avgBasketVolume },
              ]}
            />
          ) : (
            <>
              <StatsRow>
                <Stat title="Chiffre d’affaires" value={sales.volume} />
                <Stat title="Achats" value={sales.purchasesCount} />
              </StatsRow>
              <StatsRow>
                <Stat title="Panier moyen" value={sales.avgBasketValue} />
                <Stat title="Panier moyen">
                  <SubStat title="articles" value={sales.avgBasketVolume} />
                </Stat>
              </StatsRow>
            </>
          ))}
        </Card>
      </Col>
      <Col md={columnWidthMd} xs={24}>
        <Card
          title="Utilisateurs"
          icon={<UserOutlined style={{ float: 'right', fontSize: 20 }} />}
          loading={loading}
          style={{ marginBottom: 16 }}
        >
          {loaded && (mobile ? (
            <MobileList
              items={[
                { title: 'Nombre d’utilisateurs', value: users.activeCount },
                { title: 'Nombre total', value: users.totalCount },
                { title: 'Répartition (fid. / employés)', value: (<span>{users.loyalCount} / {users.employesCount}</span>) },
              ].filter(item => item.value !== undefined)}
            />
          ) : (
            <>
              <StatsRow>
                <Stat title="Utilisateurs" value={users.activeCount} />
                {users.totalCount !== undefined && (
                  <Stat title="Total">
                    <SubStat title="inscrits" value={users.totalCount} />
                  </Stat>
                )}
              </StatsRow>
              <StatsRow>
                <Stat title="Répartition">
                  <SubStat title="fidélisés" value={users.loyalCount} />
                  <div style={{ width: 20 }}></div>
                  <SubStat title="employés" value={users.employesCount} />
                </Stat>
              </StatsRow>
            </>
          ))}
        </Card>
      </Col>
      {(stats && stats.wallets_credits_cents) && (
        <Col md={columnWidthMd} xs={24}>
          <Card
            title="Wallet"
            icon={<EuroOutlined style={{ float: 'right', fontSize: 20 }} />}
            loading={loading}
            style={{ marginBottom: 16 }}
          >
            {loaded && (mobile ? (
              <MobileList
                items={[
                  { title: 'Total crédité', value: wallets.credited },
                  { title: 'Total disponible', value: wallets.available },
                  { title: 'Total viré', value: wallets.transfered },
                ]}
              />
            ) : (
              <StatsRow>
                <Stat title="Wallets">
                  <SubStat title="total crédité" value={wallets.credited} />
                  <div style={{ width: 20 }}></div>
                  <SubStat title="total disponible" value={wallets.available} />
                  <div style={{ width: 20 }}></div>
                  <SubStat title="total viré" value={wallets.transfered} />
                </Stat>
              </StatsRow>
            ))}
          </Card>
        </Col>
      )}
    </Row>
  );
};

export default StoresStats;
