import moment from 'moment';

export const shortCalendar = (date) => {
  const now = moment();
  const parsedDate = moment(date);
  if (now.format('DD-MM-YYYY') === parsedDate.format('DD-MM-YYYY')) {
    return parsedDate.format('HH:mm');
  }
  return parsedDate.calendar();
};
