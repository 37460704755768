import React from 'react';
import { LinkOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { API_URL } from '../../config';

const ActiveAdminLink = ({ url, title }) => (
  <a href={`${API_URL}/admin${url}`} title={title} target="_blank" rel="noopener noreferrer">
    <Button size="small" type="primary">ActiveAdmin <LinkOutlined /></Button>
  </a>
);

export default ActiveAdminLink;
