import axios from 'axios';
import { API_URL } from '../config';
import moment from 'moment';

const buildUrl = (sourceUrl, options) => {
  let url = sourceUrl + '?';

  if (options.queryParams) {
    url = url + Object.entries(options.queryParams).map((item) => (item.join('='))).join('&');
  }

  return encodeURI(url);
};

const apiAxios = () => {
  const headers = {
    'Accept': 'application/json',
  };
  if (localStorage.getItem('user.token')) {
    headers['X-BACKOFFICE-USER-TOKEN'] = localStorage.getItem('user.token');
  }
  return axios.create({
    baseURL: API_URL,
    headers,
  });
};

export const signIn = ({ email, password, grant_type}) => {
  if (grant_type === 'token')
    return apiAxios().post('/backoffice/users/sign_in', { backoffice_user: {
      grant_type,
    }});
  else if (grant_type === 'password')
    return axios.post('/backoffice/users/sign_in', {
      backoffice_user: {
        email,
        password,
        grant_type,
      },
    }, { baseURL: API_URL });
};

export const signOut = () => {
  return apiAxios().delete('/backoffice/users/sign_out', { baseURL: API_URL });
};

export const sendResetPassword = (email) => {
  return apiAxios().post('/backoffice/users/send_reset_password', { email });
};

export const getBackofficeUserByResetToken = async ({ token }) => {
  return (await apiAxios().get(`/backoffice/users/by_reset_token?token=${token}`)).data;
};

export const resetPassword = ({ password, password_confirmation, token }) => {
  return apiAxios().post('/backoffice/users/reset_password', { password, password_confirmation, token });
};

export const backofficeUser = (userId) => {
  return apiAxios().get(`/backoffice/users/${userId}`);
};

export const getPurchases = async ({ storesIds, partnersIds, search, chargeStatus, page = 1 }) => {
  const url = '/backoffice/purchases';
  const queryParams = {
    page,
  };
  if (storesIds && storesIds.length > 0) {
    queryParams.stores_ids = storesIds.join(',');
  }
  if (partnersIds && partnersIds.length > 0) {
    queryParams.partners_ids = partnersIds.join(',');
  }
  if (search) {
    queryParams.search = search;
  }
  if (chargeStatus) {
    queryParams.charge_status = chargeStatus;
  }

  const purchases = await apiAxios().get(buildUrl(url, { queryParams }));
  return purchases.data;
};
export const getPurchasesReport = async ({ storeId, date }) => {
  const url = '/backoffice/purchases/report';
  const queryParams = {
    store_id: storeId,
    date,
  };

  const purchases = await apiAxios().get(buildUrl(url, { queryParams }));

  return purchases.data.purchases;
};
export const getPurchase = (purchaseId) => {
  return apiAxios().get(`/backoffice/purchases/${purchaseId}`);
};
export const getPurchasesStats = async (storesIds, partnersIds, minDate, groupBy) => {
  const url = '/backoffice/purchases/stats';
  const queryParams = {};
  if (storesIds && storesIds.length > 0) {
    queryParams.stores_ids = storesIds.join(',');
  }
  if (partnersIds && partnersIds.length > 0) {
    queryParams.partners_ids = partnersIds.join(',');
  }
  if (minDate) {
    queryParams.min_date = moment(minDate).toISOString();
  }
  if (groupBy) {
    queryParams.group_by = groupBy;
  }

  const stats = await apiAxios().get(buildUrl(url, { queryParams }));

  return stats.data.purchases.map((data) => {
    return {
      ...data,
      date: moment(data.date),
    };
  });
};
export const refundPurchase = (purchaseId) => {
  return apiAxios().put(`/backoffice/purchases/${purchaseId}/refund`);
};

export const getPurchasedProductsStats = async (storesIds, partnersIds, minDate) => {
  const url = '/backoffice/purchased_products/stats';
  const queryParams = {};
  if (storesIds && storesIds.length > 0) {
    queryParams.stores_ids = storesIds;
  }
  if (partnersIds && partnersIds.length > 0) {
    queryParams.partners_ids = partnersIds;
  }
  if (minDate) {
    queryParams.min_date = moment(minDate).toISOString();
  }

  const stats = await apiAxios().get(buildUrl(url, { queryParams }));

  return {
    purchasedProductsByDay: stats.data.stats.per_day.map((data) => {
      return {
        ...data,
        date: moment(data.date),
      };
    }),
    nbPurchasesPerNbProducts: stats.data.stats.nb_purchases_per_nb_products,
    nbPurchasesPerPrice: stats.data.stats.nb_purchases_per_price,
  };
};

export const getUsersStats = async (storesIds, partnersIds) => {
  const url = '/backoffice/users/stats';
  const queryParams = {};
  if (storesIds && storesIds.length > 0) {
    queryParams.stores_ids = storesIds;
  }
  if (partnersIds && partnersIds.length > 0) {
    queryParams.partners_ids = partnersIds;
  }

  const stats = (await apiAxios().get(buildUrl(url, { queryParams }))).data;

  const data = {
    newActiveUsersByDay: stats.new_active_users_by_day.map((data) => {
      return {
        ...data,
        date: moment(data.date),
      };
    }),
  };

  if (stats.new_users_by_day) {
    data.newUsersByDay = stats.new_users_by_day.map((data) => {
      return {
        ...data,
        date: moment(data.date),
      };
    });
  }

  return data;
};
export const getUsersStatsFrequency = async (storesIds, partnersIds, minDate, maxDate) => {
  const url = '/backoffice/users/stats_frequency';
  const queryParams = {};
  if (storesIds && storesIds.length > 0) {
    queryParams.stores_ids = storesIds;
  }
  if (partnersIds && partnersIds.length > 0) {
    queryParams.partners_ids = partnersIds;
  }
  if (minDate) {
    queryParams.min_date = minDate;
  }
  if (maxDate) {
    queryParams.max_date = maxDate;
  }

  return (await apiAxios().get(buildUrl(url, { queryParams }))).data;
};
export const getLoyaltyStats = async (storesIds, partnersIds, groupBy) => {
  const url = '/backoffice/loyalty_programs/stats';
  const queryParams = {};
  if (storesIds && storesIds.length > 0) {
    queryParams.stores_ids = storesIds;
  }
  if (partnersIds && partnersIds.length > 0) {
    queryParams.partners_ids = partnersIds;
  }
  if (groupBy) {
    queryParams.group_by = groupBy;
  }

  const stats = await apiAxios().get(buildUrl(url, { queryParams }));

  return stats.data.stats.map((data) => {
    return {
      ...data,
      date: moment(data.date),
    };
  });
};
export const getStoresStats = async (storesIds, partnersIds) => {
  const url = '/backoffice/stores/stats';
  const queryParams = {};
  if (storesIds && storesIds.length > 0) {
    queryParams.stores_ids = storesIds;
  }
  if (partnersIds && partnersIds.length > 0) {
    queryParams.partners_ids = partnersIds;
  }

  const stats = await apiAxios().get(buildUrl(url, { queryParams }));

  return stats.data.stats;
};
export const getStoresReport = async (minDate, maxDate) => {
  const url = '/backoffice/stores/report';
  const queryParams = {};
  if (minDate) {
    queryParams.min_date = minDate;
  }
  if (maxDate) {
    queryParams.max_date = maxDate;
  }

  const stats = await apiAxios().get(buildUrl(url, { queryParams }));

  return stats.data.stores_purchases;
};
export const exportPurchases = async (minDate, maxDate, storesIds, partnersIds) => {
  const url = '/backoffice/purchases/export';
  const queryParams = {};

  if (minDate) {
    queryParams.min_date = minDate;
  }
  if (maxDate) {
    queryParams.max_date = maxDate;
  }
  if (storesIds && storesIds.length > 0) {
    queryParams.stores_ids = storesIds;
  }
  if (partnersIds && partnersIds.length > 0) {
    queryParams.partners_ids = partnersIds;
  }

  const purchases = await apiAxios().get(buildUrl(url, { queryParams }));

  return purchases.data;
};

export const getTpvNumbers = async (storeId) => {
  return (await apiAxios().get(`/backoffice/tpv_numbers?store_id=${storeId}`)).data.tpv_numbers;
};
export const createTpvNumber = (store_id, tpv_number) => {
  return apiAxios().post('/backoffice/tpv_numbers', { store_id, tpv_number });
};
export const updateTpvNumber = (id, tpv_number) => {
  return apiAxios().put(`/backoffice/tpv_numbers/${id}`, { tpv_number });
};
export const deleteTpvNumber = (id) => {
  return apiAxios().delete(`/backoffice/tpv_numbers/${id}`);
};

export const getStores = async () => {
  return (await apiAxios().get('/backoffice/stores')).data.stores;
};
export const getStore = async (id) => {
  return (await apiAxios().get(`/backoffice/stores/${id}`)).data;
};

export const getAppUsers = async (storeId) => {
  return (await apiAxios().get(`/backoffice/app_users?store=${storeId}`)).data.users;
};
export const getAppUser = async (userId) => {
  return (await apiAxios().get(`/backoffice/app_users/${userId}`)).data;
};
export const createAppUser = async (user) => {
  return (await apiAxios().post('/backoffice/app_users', { user }));
};
export const updateAppUser = async (userId, user) => {
  return await apiAxios().put(`/backoffice/app_users/${userId}`, { user });
};

export const getBackofficeUsers = async (storeId) => {
  return (await apiAxios().get(`/backoffice/users?store=${storeId}`)).data.users;
};
export const getBackofficeUser = async (id) => {
  return (await apiAxios().get(`/backoffice/users/${id}`)).data;
};
export const createBackofficeUser = async (backoffice_user) => {
  return await apiAxios().post('/backoffice/users', { backoffice_user });
};
export const updateBackofficeUser = async (id, backoffice_user) => {
  return await apiAxios().put(`/backoffice/users/${id}`, { backoffice_user });
};
export const inviteBackofficeUser = async (backoffice_user_id) => {
  return await apiAxios().post(`/backoffice/users/${backoffice_user_id}/invite`);
};

export const getMissingBarcodes = async (storeId) => {
  return (await apiAxios().get(`/backoffice/missing_barcodes?store_id=${storeId}`)).data.missing_barcodes;
};
