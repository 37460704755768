import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import ChargeStatus from './ChargeStatus';

const Option = Select.Option;

const CHARGE_STATUS = [
  'charge_processed',
  'charge_suspicious',
  'charge_errored',
  'refunded',
];
const DEFAULT_STATUS = 'charge_processed';

const SelectChargeStatus = (parameters) => {
  const { allOption = false, multiple = false, style = {}, ...props } = parameters;
  const defaultValue = props.defaultValue || (multiple ? undefined : (allOption ? '' : DEFAULT_STATUS));
  const mode = multiple ? 'multiple' : undefined;

  return (
    <Select
      {...props}
      defaultValue={defaultValue}
      style={{ width: 230, ...style }}
      mode={mode}
    >
      {allOption && (<Option value="">Statut du paiement{'\u00A0'}: tous</Option>)}
      {CHARGE_STATUS.map((status) => (
        <Option value={status} key={status}>
          Paiements <ChargeStatus status={status} />
        </Option>
      ))}
    </Select>
  );
};

SelectChargeStatus.propTypes = {
  style: PropTypes.object,
  onChange: PropTypes.func,
  allOption: PropTypes.bool,
  multiple: PropTypes.bool,
};

export default SelectChargeStatus;
