import _ from 'lodash';
import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD';
const REDUCERS = {
  sum: _.sum,
  avg: (values) => {
    if (values.length === 0) {
      return 0;
    } else {
      return _.sum(values) / values.length;
    }
  },
};

const fillMissingDates = (dailyData, minDate, maxDate, defaultData) => {
  minDate = minDate || _.minBy(dailyData, 'date').date;
  maxDate = maxDate || _.maxBy(dailyData, 'date').date;

  const dailyDataFilled = dailyData.filter((data) => {
    return data.date.isSameOrAfter(minDate) && data.date.isSameOrBefore(maxDate);
  });

  const datesWithData = dailyData.map((d) => d.date.format(DATE_FORMAT));

  for (let date = moment(minDate); date <= maxDate; date.add(1, 'day')) {
    if (datesWithData.indexOf(date.format(DATE_FORMAT)) === -1) {
      dailyDataFilled.push({
        ...defaultData,
        date: moment(date),
      });
    }
  }

  return dailyDataFilled;
};

const aggregateDates = (dailyData, series, aggBy = 'day') => {
  if (aggBy === 'day') {
    return dailyData;
  } else {
    const grouped = _.groupBy(dailyData, (data) => {
      return moment(data.date).startOf(aggBy).format(DATE_FORMAT);
    });
    const aggData = [];

    // Waiting for eslint fix to be merged into react-scripts
    // [TODO] Remove this
    // eslint-disable-next-line
    for (const date in grouped) {
      const groupedData = { date: moment(date) };
      series.forEach((serie) => {
        if (typeof serie.reducer === 'string') {
          groupedData[serie.dataKey] = REDUCERS[serie.reducer](_.map(grouped[date], serie.dataKey));
        } else {
          groupedData[serie.dataKey] = serie.reducer(grouped[date]);
        }
      });
      aggData.push(groupedData);
    }

    return aggData;
  }
};

const sortDates = (data) => {
  return _.sortBy(data, 'date').map((d) => {
    return {
      ...d,
      date: d.date.format(DATE_FORMAT),
    };
  });
};

const shortDateFormatter = (aggBy = 'day') => {
  return (date) => {
    let format = 'DD/MM';
    if (aggBy === 'week') {
      format = 'DD/MM';
    } else if (aggBy === 'month') {
      format = 'MMM YY';
    } else if (aggBy === 'year') {
      format = 'YYYY';
    }
    return moment(date).format(format);
  };
};

const longDateFormatter = (aggBy = 'day') => {
  return (date) => {
    let format = 'dddd DD MMMM YYYY';
    if (aggBy === 'week') {
      return `${moment(date).format('DD MMMM YYYY')} → ${moment(date).endOf('week').format('DD MMMM YYYY')}`;
    } else if (aggBy === 'month') {
      format = 'MMMM YYYY';
    } else if (aggBy === 'year') {
      format = 'YYYY';
    }
    return moment(date).format(format);
  };
};

export {
  aggregateDates,
  shortDateFormatter,
  longDateFormatter,
  fillMissingDates,
  sortDates,
};
