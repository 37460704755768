import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'antd';

export default ({ user }) => {
  const cardTitle = `${user.given_name} ${user.family_name}`.trim() || 'Utilisateur';

  return (
    <Card title={cardTitle} extra={<Link to={`/queuebusting/users/${user.id}/edit`}>Modifier</Link>}>
      <b>Adresse email{'\u00A0'}:</b> {user.email}<br />
      <b>Magasins accessibles{'\u00A0'}:</b> {user.stores.length === 0 ? 'aucun' : user.stores.map(p => p.name).join(',\u00A0')}
    </Card>
  );
};
