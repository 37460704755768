import React from 'react';
import { withRouter } from '../../hooks/withRouter';
import useUser from '../../hooks/useUser';

import { Row, Col, Spin } from 'antd';
import UserCard from '../../components/Queuebuster/UserCard';

export default withRouter(({ params }) => {
  const user = useUser(params.userId);

  if (!user) {
    return (<div style={{ textAlign: 'center', maginTop: 16 }}><Spin size="large" tip={`Chargement de l’utilisateur ${params.userId}...`} /></div>);
  }

  return (
    <Row type="flex" justify="center">
      <Col xs={24} md={16} lg={14}>
        <UserCard user={user} />
      </Col>
    </Row>
  );
});
