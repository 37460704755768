import React from 'react';
import PropTypes from 'prop-types';

const StoreLink = ({ store }) => {
  return (
    <span>{store.name}</span>
  );
};

StoreLink.propTypes = {
  store: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

export default StoreLink;
