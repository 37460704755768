import React from 'react';
import { Select, TreeSelect } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './SelectStore.css';

const Option = Select.Option;

const SelectStore = (parameters) => {
  const {
    stores,
    partners,
    allOption = false,
    multiple = false,
    style = {},
    selectedStores,
    selectedPartners,
    defaultStores,
    defaultPartners,
    ...props
  } = parameters;

  // If single choice, or if the user has access to zero or one partner: <Select> with stores
  if (!multiple || !partners || partners.length < 2) {
    const defaultValue = props.defaultValue || defaultStores || (multiple ? undefined : ((allOption || stores.length === 0) ? '' : stores[0].id));
    const mode = multiple ? 'multiple' : undefined;
    if (selectedStores) {
      props.value = selectedStores;
    }

    return (
      <Select
        {...props}
        defaultValue={defaultValue}
        style={{ ...style }}
        mode={mode}
        showSearch
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        autoClearSearchValue={false}
      >
        {allOption && (<Option value="">Tous</Option>)}
        {_.orderBy(stores, 'name').map((store) => {
          return (
            <Option value={store.id} key={store.id}>{store.name}</Option>
          );
        })}
      </Select>
    );
  }

  // Else, <TreeSelect> with partners and stores
  const SHOW_PARENT = TreeSelect.SHOW_PARENT;
  const treeData = partners.map(partner => ({
    title: partner.name,
    value: 'partner-' + partner.id,
    key: 'partner-' + partner.id,
    children: stores.filter(s => s.partner.id === partner.id).map(store => ({
      title: store.name,
      value: 'store-' + store.id,
      key: 'store-' + store.id,
    })),
  }));
  stores.forEach(store => {
    if (!partners.find(partner => partner.id === store.partner.id)) {
      treeData.push({
        title: store.name,
        value: 'store-' + store.id,
        key: 'store-' + store.id,
      });
    }
  });

  const tProps = {
    treeData,
    onChange: (keys) => {
      const partners = keys.filter(k => /^partner-/.test(k)).map(k => k.replace(/^partner-/, ''));
      const stores = keys.filter(k => /^store-/.test(k)).map(k => k.replace(/^store-/, ''));
      props.onChange(stores, partners, true);
    },
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: props.placeholder || 'Tous',
    style: { width: 300, ...style },
    filterTreeNode: (input, option) => option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
  };
  if (selectedStores || selectedPartners) {
    tProps.value = [];
    if (selectedStores) {
      selectedStores.forEach(s => tProps.value.push('store-' + s));
    }
    if (selectedPartners) {
      selectedPartners.forEach(p => tProps.value.push('partner-' + p));
    }
  }
  if (defaultStores || defaultPartners) {
    tProps.defaultValue = [];
    if (defaultStores) {
      defaultStores.forEach(s => tProps.defaultValue.push('store-' + s));
    }
    if (defaultPartners) {
      defaultPartners.forEach(p => tProps.defaultValue.push('partner-' + p));
    }
  }
  return <TreeSelect {...tProps} />;
};

SelectStore.propTypes = {
  stores: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    partner: PropTypes.object,
  })),
  partners: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  defaultStores: PropTypes.arrayOf(PropTypes.string),
  defaultPartners: PropTypes.arrayOf(PropTypes.string),
  style: PropTypes.object,
  onChange: PropTypes.func,
  allOption: PropTypes.bool,
  multiple: PropTypes.bool,
};

export default SelectStore;
