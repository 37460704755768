import React, { Suspense, lazy } from 'react';
import { Alert, Card, Divider } from 'antd';
import StoreStatus from './StoreStatus';

const Map = lazy(() => import('./StoreMap'));

const customNl2br = (text) => {
  if (text)
    return (text.split('\n').map((line, i) => (
      <span key={i}>
        {line}
        <br/>
      </span>
    )));
  return '';
};

const StoreCard = ({ store, loading }) => {
  if (loading) {
    return (<Card loading />);
  }

  return (
    <Card
      title={store.name}
      extra={<StoreStatus status={store.status} />}
      cover={store.home_image ? (<img alt="Magasin" src={store.home_image.original.url} />) : null}
    >
      {store.status === 'inactive' && (
        <Alert type="info" message="Ce magasin est inactif et n’est pas affiché aux utilisateurs." showIcon style={{ marginBottom: 16 }} />
      )}
      <b>Partenaire{'\u00A0'}:</b> {store.partner.name}<br />
      <b>Adresse{'\u00A0'}</b> {store.address}<br />
      <b>Recherche de produits{'\u00A0'}</b> {store.is_product_search_supported ? 'activée' : 'désactivée'}<br />
      <b>Identifiant externe{'\u00A0'}</b> {store.external_identifier}<br />
      <b>Nombre d’articles max{'\u00A0'}</b> {store.maximum_cart_size}<br />
      {store.billing_entity && (<><b>Adresse de facturation{'\u00A0'}</b><br />{customNl2br(store.billing_entity.billing_details)}<br /></>)}

      <Divider>Message d’accueil</Divider>
      {store.welcome_title && (<><b>{store.welcome_title}</b><br /></>)}
      {customNl2br(store.welcome_message)}

      <Divider>Message de fin</Divider>
      {store.goodbye_title && (<><b>{store.goodbye_title}</b><br /></>)}
      {customNl2br(store.goodbye_message)}

      <Suspense fallback={null}>
        <Map
          store={store}
          style={{ width: 'calc(100% + 48px)', height: 250, marginLeft: -24, marginBottom: -24, marginRight: -24, marginTop: 24 }}
        />
      </Suspense>
    </Card>
  );
};

export default StoreCard;
