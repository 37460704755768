import React from 'react';
import PropTypes from 'prop-types';
import { Table, Row, Col, Card } from 'antd';
import VerificationStatus from './VerificationStatus';
import UserLink from '../User/UserLink';
import moment from 'moment';
import Price from '../Price/Price';
import _ from 'lodash';

const Verification = ({ is_valid, gatekeeper, date, items }) => {
  const columns = [
    {
      title: 'Code',
      dataIndex: 'barcode',
    },
    {
      title: 'Nom',
      dataIndex: 'name',
    },
    {
      title: 'Prix unit.',
      dataIndex: 'unit_price',
    },
    {
      title: 'Qté.',
      dataIndex: 'quantity',
    },
    {
      title: 'Prix total',
      dataIndex: 'total_price',
    },
  ];
  items = items.map((item, i) => {
    const o = {
      key: i,
      name: '—',
      barcode: item.barcode,
      unit_price: '—',
      quantity: item.quantity,
      total_price: '—',
    };
    if (item.product) {
      o.name = item.product.name;
      o.unit_price = (<Price {...item.product.unit_price} />);
      o.total_price = (<Price currency={item.product.unit_price.currency} cents={item.quantity * item.product.unit_price.cents} />);
    }
    return o;
  });

  return (
    <div style={{ marginBottom: 16 }}>
      <Row gutter={16}>
        <Col span={8}>
          <Card title="Détails">
            <strong>Date{'\u00A0'}:</strong> {moment(date).format('LLLL').toLocaleLowerCase()}<br />
            <strong>Statut{'\u00A0'}:</strong> <VerificationStatus verification_is_valid={is_valid} /><br />
            <strong>Vigile{'\u00A0'}:</strong> <UserLink user={gatekeeper} /><br />
            <strong>Nombre d’articles scannés{'\u00A0'}:</strong> {_.sumBy(items, 'quantity')}
          </Card>
        </Col>
        <Col span={16}>
          <Table
            title={() => (<strong>Articles contrôlés</strong>)}
            bordered
            dataSource={items}
            columns={columns}
            pagination={false}
          />
        </Col>
      </Row>
    </div>
  );
};

Verification.propTypes = {
  is_valid: PropTypes.bool.isRequired,
  gatekeeper: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

export default Verification;
