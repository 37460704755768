import React, { useState, useEffect, useContext } from 'react';
import { SessionContext } from '../../context/session-context';
import { UserAddOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import SelectStore from '../../components/SelectStore/SelectStore';
import { getBackofficeUsers } from '../../services/api';
import BackofficeUsersTable from '../../components/BackofficeUser/BackofficeUsersTable';
import BackofficeUsersList from '../../components/BackofficeUser/BackofficeUsersList';
import { Link } from 'react-router-dom';
import Responsive from 'react-responsive';

const { Title } = Typography;

export default () => {
  const [session] = useContext(SessionContext);
  const [users, setUsers] = useState([]);

  const storeChange = async (storeId) => {
    const users = await getBackofficeUsers(storeId);
    setUsers(users);
  }; 

  useEffect(() => {
    storeChange(session.user.all_stores[0].id);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Title>Utilisateurs backoffice</Title>
      <Responsive minWidth={768}>
        {(largeScreen) => (
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
            <div style={!largeScreen ? { flex: 1, width: 200, paddingRight: 16 } : null}>
              {largeScreen && (<span>Magasin{'\u00A0'}: </span>)}
              <SelectStore
                stores={session.user.all_stores}
                onChange={storeChange}
                allOption={false}
                style={!largeScreen ? { width: '100%' } : { width: '30vw' }}
              />
            </div>
            <div>
              <Link to="/users/new">
                <Button icon={<UserAddOutlined />}>{largeScreen && 'Créer un utilisateur backoffice'}</Button>
              </Link>
            </div>
          </div>
        )}
      </Responsive>
      <Responsive minWidth={768}>
        <BackofficeUsersTable users={users} />
      </Responsive>
      <Responsive maxWidth={767}>
        <BackofficeUsersList users={users} />
      </Responsive>
    </div>
  );
};
