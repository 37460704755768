import React from 'react';
import DateChart from '../DateChart/DateChart';

const MoneyDateChart = ({ data, series, aggBy = 'day', loading, ...props }) => {
  const dataInteger = data.map((d) => {
    const o = { ...d };
    series.forEach((serie) => {
      if (o[serie.dataKey] !== undefined) {
        o[serie.dataKey] /= 100;
      }
    });
    return o;
  });

  return (
    <DateChart
      unit="€"
      {...props}
      series={series}
      data={dataInteger}
      aggBy={aggBy}
      loading={loading}
    />
  );
};

export default MoneyDateChart;
