import React from 'react';
import { Card, Row, Col } from 'antd';
import moment from 'moment';
import data from './changelog-data';

export default () => {
  return (
    <Row type="flex" justify="center">
      <Col xs={24} md={16} lg={14}>
        {data.map((entry, i) => (
          <Card
            key={i}
            style={{ marginBottom: '2rem' }}
            title={entry.title} extra={<small>{moment(entry.date).format('dddd DD MMMM YYYY')}</small>}
          >
            {entry.content}
          </Card>
        ))}
      </Col>
    </Row>
  );
};
