import { signIn, signOut, backofficeUser } from './api';

export const createSession = async ({ email, password, token }, dispatch) => {
  let grant_type = '';

  if (token) {
    grant_type = 'token';
    localStorage.setItem('user.token', token);
  }
  else
    grant_type = 'password';

  const signInResponse = await signIn({ email, password, grant_type });
  const data = signInResponse.data;

  localStorage.setItem('user.token', data.token);
  localStorage.setItem('user.id', data.user.id);

  const user = await backofficeUser(data.user.id);
  dispatch({ type: 'USER_UPDATED', payload: user.data });

  return data;
};

export const deleteSession = async (dispatch) => {
  await signOut().then(() => {
    localStorage.clear();
    dispatch({ type: 'USER_DISCONNECTED' });
  });
};

export const restoreSession = async (dispatch) => {
  if (localStorage.getItem('user.token') && localStorage.getItem('user.id')) {
    const user = await backofficeUser(localStorage.getItem('user.id'));
    dispatch({ type: 'USER_UPDATED', payload: user.data });
  }
};
