import React from 'react';
import { withRouter } from '../../hooks/withRouter';
import useBackofficeUser from '../../hooks/useBackofficeUser';

import { Alert, Row, Col, Spin } from 'antd';
import UserCard from '../../components/BackofficeUser/BackofficeUserCard';

export default withRouter(({ params }) => {
  const [user, error] = useBackofficeUser(params.userId);

  if (!user) {
    if (error) {
      const status = error.response?.status;
      const message =
        status === 404
          ? 'L’utilisateur est introuvable.'
          : `Une erreur s’est produite${'\u00A0'}: ${error}.`;

      return <Alert type="error" message={message} />;
    }

    return (
      <div style={{ textAlign: 'center', maginTop: 16 }}>
        <Spin
          size="large"
          tip={`Chargement de l’utilisateur ${params.userId}...`}
        />
      </div>
    );
  }

  return (
    <Row type="flex" justify="center">
      <Col xs={24} md={16} lg={14}>
        <UserCard user={user} />
      </Col>
    </Row>
  );
});
