import React, { useState, useEffect } from 'react';
import { withRouter } from '../../hooks/withRouter';
import { Col, Row } from 'antd';
import { getStores, getStore } from '../../services/api';
import StoresTable from '../../components/Store/StoresTable';
import StoreCard from '../../components/Store/StoreCard';
import Responsive from 'react-responsive';

export default withRouter(({ navigate, params }) => {
  const [loading, setLoading] = useState(true);
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState();
  useEffect(() => {
    getStores().then(stores => {
      if (params.partnerId)
        setStores(stores.filter((store) => (store.partner.id === params.partnerId)));
      else
        setStores(stores);
      setLoading(false);
    });
  }, [params.partnerId]);

  return (
    <Row gutter={16}>
      <Col lg={14} md={24} xxl={16}>
        <Responsive minWidth={768}>
          {(largeScreen) => (
            <StoresTable
              stores={stores}
              loading={loading}
              size={largeScreen ? 'default' : 'middle'}
              style={largeScreen ? null : { marginLeft: -16, marginRight: -16 }}
              onSelectStore={async (store) => {
                if (largeScreen) {
                  setStore(await getStore(store.id));
                } else {
                  navigate(`/stores/${store.id}`);
                }
              }}
            />
          )}
        </Responsive>
      </Col>
      <Col lg={10} md={24} xxl={8}>
        {store && (<StoreCard store={store} />)}
      </Col>
    </Row>
  );
});
