import React, { useContext, useState } from 'react';
import { SessionContext } from '../../context/session-context';
import { Card, Input, Row, Col, Button, Alert, Form } from 'antd';
import { updateBackofficeUser, backofficeUser } from '../../services/api';
import { createSession } from '../../services/session';
import { validatePasswordEditRules } from '../../utils/PasswordUtils';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};


const EditProfile = () => {
  const [ session, dispatch ] = useContext(SessionContext);
  const [state, setState] = useState({});

  const handleSubmitInfos = ({ given_name, family_name, email }) => {
    setState({
      ...state,
      error_infos: undefined,
      success_infos: false,
      password: undefined,
      password_confirmation: undefined,
    });

    updateBackofficeUser(
      session.user.id,
      { given_name, family_name, email },
    )
      .then(async () => {
        setState({
          ...state,
          success_infos: true,
        });
        const user = await backofficeUser(session.user.id);
        dispatch({ type: 'USER_UPDATED', payload: user.data});
      })
      .catch(error => {
        setState({
          ...state,
          success_infos: false,
        });
        if (error.response?.data?.error) {
          setState({
            ...state,
            error_infos: error.response.data.error,
          });
        } else {
          setState({
            ...state,
            error_infos: 'Impossible de modifier vos informations',
          });
        }
      });
  };

  const handleSubmitPassword = ({ current_password, password, password_confirmation }) => {
    setState({
      ...state,
      error_password: undefined,
      success_password: false,
      current_password, password, password_confirmation,
    });

    updateBackofficeUser(
      session.user.id,
      { current_password, password, password_confirmation },
    )
      .then(async () => {
        await createSession({
          email: session.user.email,
          password: state.password,
        }, dispatch);

        setState({
          ...state,
          success_password: true,
        });
      })
      .catch(error => {
        setState({
          ...state,
          success_password: false,
        });
        if (error.response?.data?.error) {
          setState({
            ...state,
            error_password: error.response.data.error,
          });
        } else {
          setState({
            ...state,
            error_password: 'Impossible de modifier votre mot de passe.',
          });
        }
      });
  };

  const passwordFieldsNotEmpty = () => {
    const currentPassword = state.current_password;
    const password = state.password;
    const passwordConfirmation = state.password_confirmation;

    return currentPassword || password || passwordConfirmation;
  };

  const canSubmitPassword = () => {
    const currentPassword = state.current_password;
    const password = state.password;
    const passwordConfirmation = state.password_confirmation;

    return validatePasswordEditRules(currentPassword, password, passwordConfirmation);
  };

  const handlePasswordChange = (values) => {
    setState({ ...state, ...values});
  };

  const changePasswordButton = (
    <Button
      type="primary"
      htmlType="submit"
      disabled={!canSubmitPassword()}
    >
      Modifier votre mot de passe
    </Button>
  );

  return (
    <div>
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <Form onFinish={handleSubmitInfos}>
            <Card
              title="Informations personnelles"
              actions={[
                <Button key="one" type="primary" htmlType="submit">
                  Modifier
                </Button>,
              ]}
              style={{ marginBottom: 16 }}
            >
              <Form.Item {...formItemLayout} name='given_name' label="Prénom" initialValue={session.user.given_name}>
                <Input type="text" placeholder="Marie" />
              </Form.Item>
              <Form.Item {...formItemLayout} name='family_name' label="Nom" initialValue={session.user.family_name}>
                <Input type="text" placeholder="Curie" />
              </Form.Item>
              <Form.Item {...formItemLayout} name='email' label="Adresse email" initialValue={session.user.email}>
                <Input type="email" placeholder="marie.curie@gmail.com" />
              </Form.Item>
              {state.error_infos && (
                <Alert message={state.error_infos} type="error" />
              )}
              {state.success_infos && (
                <Alert
                  message={
                    <span>Vos informations ont bien été modifiées.</span>
                  }
                  type="success"
                />
              )}
            </Card>
          </Form>
        </Col>
        <Col md={12} xs={24}>
          <Form onFinish={handleSubmitPassword} onValuesChange={handlePasswordChange}>
            <Card title="Mot de passe" actions={[changePasswordButton]}>
              <Form.Item {...formItemLayout} name='current_password' label="Ancien mot de passe">
                <Input type="password" placeholder="********" />
              </Form.Item>
              <Form.Item {...formItemLayout} name='password' label="Mot de passe">
                <Input type="password" placeholder="********" />
              </Form.Item>
              <Form.Item {...formItemLayout} name='password_confirmation' label="Confirmation">
                <Input type="password" placeholder="********" />
              </Form.Item>
              {state.error_password && (
                <Alert message={state.error_password} type="error" />
              )}
              { passwordFieldsNotEmpty() && !canSubmitPassword() && (
                <Alert message={
                  <span>Votre mot de passe doit contenir au moins dix caractères dont une majuscule, une minuscule, un chiffre et un caractère spécial</span> 
                } type="info" />
              )}
              {state.success_password && (
                <Alert
                  message={
                    <span>Votre mot de passe a bien été modifié.</span>
                  }
                  type="success"
                />
              )}
            </Card>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default EditProfile;