import React, { useContext, useState } from 'react';
import { SessionContext } from '../../context/session-context';
import { Button, Table, Row, Col, Card, Divider, List } from 'antd';
import Price from '../Price/Price';
import Tax from '../Tax/Tax';
import StoreLink from '../Store/StoreLink';
import ChargeStatus from '../ChargeStatus/ChargeStatus';
import VerificationStatus from '../Verification/VerificationStatus';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Verification from '../Verification/Verification';
import VerificationHistory from '../Verification/VerificationHistory';
import { refundPurchase } from '../../services/api';
import UserCard from '../User/UserCard';
import Platform from '../Platform/Platform';
import ActiveAdminLink from '../ActiveAdmin/ActiveAdminLink';
// import ReceiptButton from './ReceiptButton';
import Responsive from 'react-responsive';
import styled from 'styled-components';

const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  flex: 1;
`;
const ItemLine = ({ name, barcode, unit_price, quantity, total_price, discount_price, tax, has_discount }) => (
  <List.Item>
    <LineContainer>
      <div style={{ paddingLeft: 8, alignSelf: 'start' }}>
        <span style={{ color: 'gray' }}>{quantity}×</span>
      </div>
      <div style={{ flex: 1, paddingLeft: 8 }}>
        <div>
          <span style={{ fontWeight: 'bold' }}>{name}</span>
        </div>
        <div style={{ color: 'gray' }}>{barcode}</div>
        <div>{unit_price} • {tax}</div>
      </div>
      <div style={{ textAlign: 'center', paddingRight: 8 }}>
        <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>{total_price}</div>
        {has_discount && (<div style={{ color: 'gray' }}>−{discount_price}</div>)}
      </div>
    </LineContainer>
  </List.Item>
);

const RefundButton = (props) => {
  const [state, setState] = useState({
    confirming: false,
    loading: false,
    error: undefined,
  });

  const refund = () => {
    setState({
      ...state,
      loading: true,
    });
    refundPurchase(props.purchaseId)
      .then(() => {
        props.handleRefund();
      })
      .catch((error) => {
        setState({
          ...state,
          error: error.error,
        });
      });
  };

  const confirming = () => {
    setState({
      loading: true,
    });
    setTimeout(() => setState({ ...state, confirming: true, loading: false }), 1000);
  };

  if (state.error !== undefined) {
    return (
      <div>
        Une erreur s’est produite{'\u00A0'}: {state.error}
      </div>
    );
  }
  if (state.confirming) {
    return (
      <Button.Group>
        <Button loading={state.loading} type="danger" onClick={() => refund()}>Confirmer le remboursement</Button>
        <Button disabled={state.loading} type="default" onClick={() => setState({ ...state, confirming: false })}>Annuler</Button>
      </Button.Group>
    );
  }
  return (
    <Button loading={state.loading} type="danger" onClick={() => confirming()}>Rembourser{'\u00A0'}<Price {...props.totalPrice} /></Button>
  );
};

const columns = [
  {
    title: 'Code',
    dataIndex: 'barcode',
  },
  {
    title: 'Nom',
    dataIndex: 'name',
  },
  {
    title: 'Prix unit.',
    dataIndex: 'unit_price',
  },
  {
    title: 'TVA',
    dataIndex: 'tax',
  },
  {
    title: 'Qté.',
    dataIndex: 'quantity',
  },
  {
    title: 'Prix total',
    dataIndex: 'total_price',
  },
  {
    title: 'Remise',
    dataIndex: 'discount_price',
  },
];
const columnsDiscounts = [
  {
    title: 'Nom',
    dataIndex: 'label',
  },
  {
    title: 'Montant',
    dataIndex: 'amount',
  },
];

const Purchase = ({ purchase, handleUpdate }) => {
  const [ session ] = useContext(SessionContext);

  const items = purchase.items.map((item, i) => {
    const charged_price = item.charged_price || { currency: item.unit_price.currency, cents: item.quantity * item.unit_price.cents };
    return {
      key: i,
      name: item.name,
      barcode: (<span style={{ fontSize: '0.75em' }}>{item.identifier}</span>),
      unit_price: (<Price {...item.unit_price} />),
      quantity: item.quantity,
      total_price: (<Price {...charged_price} />),
      discount_price: (<Price currency={charged_price.currency} cents={item.quantity * item.unit_price.cents - charged_price.cents} />),
      has_discount: (item.quantity * item.unit_price.cents - charged_price.cents) !== 0,
      tax: (<Tax rate={item.tax_rate} />),
    };
  });
  const discounts = purchase.discounts.map((discount) => {
    return {
      key: discount.id,
      label: discount.label,
      amount: (<Price {...discount.amount_price} />),
    };
  });

  const actions = [];
  if (session.user.can_refund && purchase.payment && purchase.payment.status === 'charge_processed' && purchase.payment.processor === 'stripe') {
    actions.push(
      (<RefundButton key="1" totalPrice={purchase.total_price} purchaseId={purchase.id} handleRefund={handleUpdate} />),
    );
  }

  const cardExtra = (
    <div>
      {session.user.can_see_users && (<ActiveAdminLink url={`/purchases/${purchase.id}`} />)}
    </div>
  );

  return (
    <div>
      <Row gutter={16} style={{ marginBottom: 32 }}>
        <Col xs={24} md={8}>
          <Card
            title={`Achat ${purchase.short_identifier}`}
            actions={actions}
            extra={cardExtra}
          >
            <strong>Date{'\u00A0'}:</strong> {moment(purchase.created_at).format('LLLL:SS').toLocaleLowerCase()}<br />
            <strong>Magasin{'\u00A0'}:</strong> <StoreLink store={purchase.store} /><br />
            {purchase.platform && purchase.platform_version && (<React.Fragment>
              <strong>Plateforme{'\u00A0'}:</strong> <Platform platform={purchase.platform} version={purchase.platform_version} /><span style={{ wordBreak: 'break-all' }}>{purchase.platform_version && ` (${purchase.platform_version})`}</span><br />
            </React.Fragment>)}
            <br />

            <strong>Prix total TTC payé{'\u00A0'}:</strong> <Price {...purchase.total_price} /><br />
            {purchase.discount && (
              <React.Fragment>
                <strong>Remise{'\u00A0'}:</strong> <Price
                  currency={purchase.discount.original_price.currency}
                  cents={purchase.discount.original_price.cents - purchase.total_price.cents}
                />
                <br />
              </React.Fragment>
            )}
            <strong>Nombre d’articles{'\u00A0'}:</strong> {_.sumBy(purchase.items, 'quantity')}<br /><br />
            {purchase.payment && (<React.Fragment>
              <strong>Transaction <span style={{ textTransform: 'capitalize' }}>{purchase.payment.processor}</span>{purchase.payment.external_id && (<React.Fragment>{'\u00A0'}:</React.Fragment>)}</strong> <span style={{ fontSize: '0.7rem' }}>{purchase.payment.external_id}</span><br />
              {['pax', 'android'].includes(purchase.payment.source.type) && (<React.Fragment><strong>Vendeur{'\u00A0'}:{'\u00A0'}</strong><span style={{ fontSize: '0.7rem' }}>{purchase.payment.source.seller_email}</span><br /></React.Fragment>)}
              {purchase.payment.source.device_number && (<React.Fragment><strong>Pax{'\u00A0'}:{'\u00A0'}</strong><span style={{ fontSize: '0.7rem' }}>{purchase.payment.source.device_number}</span><br /></React.Fragment>)}
              {purchase.partner_pax_purchase_id != null && (<React.Fragment><strong>Purchase ID{'\u00A0'}:{'\u00A0'}</strong><span style={{ fontSize: '0.7rem' }}>{purchase.partner_pax_purchase_id}</span><br/></React.Fragment>)}
            </React.Fragment>)}
            <strong>Paiement{'\u00A0'}:</strong> <ChargeStatus status={purchase.payment && purchase.payment.status} /><br />
            <strong>Types de paiement{'\u00A0'}:{'\u00A0'}</strong>{purchase.payment.payment_types}<br /><br />
            <strong>Contrôle{'\u00A0'}:</strong> <VerificationStatus verification_is_valid={purchase.verification && purchase.verification.is_valid} scanned={purchase.verification_histories.length > 0} />
          </Card>

          {purchase.user && (<UserCard style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }} {...purchase.user} withDetails={session.user.can_see_users} />)}
        </Col>
        <Col xs={24} md={16}>
          <Card size="small" bodyStyle={{ padding: 0 }}>
            <Responsive minWidth={768}>
              <Table
                dataSource={items}
                columns={columns}
                pagination={false}
                size="middle"
              />
            </Responsive>
            <Responsive maxWidth={767}>
              <List
                dataSource={items}
                renderItem={item => (<ItemLine {...item} />)}
                size="small"
              />
            </Responsive>
          </Card>

          {discounts.length > 0 && (
            <Card size="small" bodyStyle={{ padding: 0 }} style={{ marginTop: '0.75rem' }}>
              <Table
                title={() => (<div style={{ fontWeight: 'bold', textAlign: 'center' }}>Remises</div>)}
                dataSource={discounts}
                columns={columnsDiscounts}
                pagination={false}
                size="middle"
              />
            </Card>
          )}
        </Col>
      </Row>
      {(purchase.verification || (purchase.verification_histories && purchase.verification_histories.length > 0)) && (
        <React.Fragment>
          <Divider>Contrôle</Divider>
          {purchase.verification && (
            <Verification {...purchase.verification} />
          )}
          <VerificationHistory history={purchase.verification_histories} />
        </React.Fragment>
      )}
    </div>
  );
};

Purchase.propTypes = {
  purchase: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      unit_price: PropTypes.object.isRequired,
    })).isRequired,
    store: PropTypes.object.isRequired,
  }),
};

export default Purchase;
