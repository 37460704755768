import React, { useState } from 'react';
import './Login.css';
import { LeftOutlined, UserOutlined } from '@ant-design/icons';
import { Card, Input, Button, Alert, Form } from 'antd';
import { Link } from 'react-router-dom';
import { sendResetPassword } from '../../services/api';
import { withRouter } from '../../hooks/withRouter';

const ResetPasswordForm = (props) => {
  const [state, setState] = useState({
    done: false,
    loading: false,
  });

  const handleSubmit = async ({ email }) => {
    setState({ ...state, loading: true });
    await sendResetPassword(email);
    setState({ ...state, done: true });
  };

  const urlParams = new URLSearchParams(props.location.search);

  return (
    <div className="LoginContainer">
      <Card title="Lyf Pay | Backoffice" className="LoginForm">
        <Form onFinish={handleSubmit}>
          {state.done ? (
            <Form.Item>
              <Alert showIcon message="Si ce compte existe, un email vient d’être envoyé à l’adresse email indiquée." type="success" />
            </Form.Item>
          ) : (
            <>
              <p>
                Indiquez votre adresse email pour réinitialiser votre mot de passe.
              </p>
              <Form.Item name='email' initialValue={urlParams.get('email')}>
                <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="email" placeholder="Adresse email" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="Button" loading={state.loading}>
                  Valider
                </Button>
              </Form.Item>
            </>
          )}
          <Link
            to="/"
            title="Retour"
          >
            <LeftOutlined /> Retour
          </Link>
        </Form>
      </Card>
    </div>
  );
};

export default withRouter(ResetPasswordForm);
