import React, { useState, useEffect } from 'react';
import { withRouter } from '../../hooks/withRouter';
import { getStoresReport } from '../../services/api';
import { DownloadOutlined } from '@ant-design/icons';
import { Alert, Button, Table } from 'antd';
import { ExcelUint8Buffer } from '../../utils/ExcelJSUtils';
import Price from '../../components/Price/Price';
import moment from 'moment';
import { sortBy, uniq } from 'lodash';

const sortOrderToN = (sortOrder) => {
  return sortOrder === 'ascend' ? 1 : -1;
};
const extractPaymentMethods = (storePurchases) => {
  return uniq(storePurchases.map(storePurchase => storePurchase.payment_methods.map(pm => pm.identifier)).flat()).sort();
};

const formatDataForExcelJS = function (sourceData) {
  const storesRanges = [];
  let currentStore;
  const data = sortBy(sourceData, ['store.name', 'store.external_identifier', 'date']);
  let formattedColumns = [
    { 
      key: 'date',
      header: 'Date',
      width: 14,
    },
    {
      key: 'store',
      header: 'Magasin',
      width: 30,
    },
    { 
      key: 'storeId',
      header: 'Identifiant',
      width: 10,
    },
    {
      key: 'nbPurchases',
      header: 'Nombre de tickets',
      style: { numFmt: '0' },
      width: 15,
    },
    { 
      key: 'chargedPrice',
      header: 'Montant total facture (EUR)',
      style: { numFmt: '0.00' },
      width: 20,
    },
    {
      key: 'originalPrice',
      header: 'Montant total avant remises (EUR)',
      style: { numFmt: '0.00' },
      width: 20,
    },
    {
      key: 'discountPrice',
      header: 'Total des remises (EUR)',
      style: { numFmt: '0.00' },
      width: 20,
    },
  ];
  const paymentMethods = extractPaymentMethods(data);
  let formattedData = [];

  paymentMethods.forEach(pm => {
    formattedColumns.push({
      key: pm,
      header: `Paye par ${pm} (EUR)`,
      style: { numFmt: '0.00' },
      width: 20,
    });
  });

  data.forEach((d, i) => {
    if (!currentStore || currentStore.storeId !== d.store.external_identifier) {
      if (currentStore && currentStore.storeId !== d.store.external_identifier) {
        storesRanges.push(currentStore);
      }
      currentStore = {
        storeId: d.store.external_identifier,
        store: d.store.name,
        start: i + 2,
        end: i + 2,
      };
    } else {
      currentStore.end += 1;
    }

    const line = [d.date, d.store.name, d.store.external_identifier, d.nb_purchases, d.charged_price.cents/100, d.original_price.cents/100, d.discount_price.cents/100];
    paymentMethods.forEach(pm => {
      const paymentMethod = d.payment_methods.find(p => p.identifier === pm);
      if (paymentMethod) {
        line.push(paymentMethod.amount.cents/100);
      } else {
        line.push(0);
      }
    });
    formattedData.push(line);
  });

  if (currentStore) {
    storesRanges.push(currentStore);
  }

  formattedData.push([]);

  storesRanges.forEach((store) => {
    const line = [
      '*** Total ***',
      store.store,
      store.storeId,
      { formula: `SUM(D${store.start}:D${store.end})`, style: { numFmt: '0' } },
      { formula: `SUM(E${store.start}:E${store.end})`, style: { numFmt: '0.00' } },
      { formula: `SUM(F${store.start}:F${store.end})`, style: { numFmt: '0.00' } },
      { formula: `SUM(G${store.start}:G${store.end})`, style: { numFmt: '0.00' } },
    ];
    paymentMethods.forEach((_pm, i) => {
      const l = String.fromCharCode(72 + i); // char 72 is H
      line.push({ formula: `SUM(${l}${store.start}:${l}${store.end})`, style: { numFmt: '0.00' }  });
    });
    formattedData.push(line);
  });

  formattedData.push([]);

  const lastLine = data.length + 2;
  const finalLine = [
    '*** TOTAL ***',
    '*** TOUS MAGASINS ***',
    '******',
    { formula: `SUM(D2:D${lastLine})`, style: { numFmt: '0' } },
    { formula: `SUM(E2:E${lastLine})`, style: { numFmt: '0.00' } },
    { formula: `SUM(F2:F${lastLine})`, style: { numFmt: '0.00' } },
    { formula:`SUM(G2:G${lastLine})`, style: { numFmt: '0.00' } },
  ];
  paymentMethods.forEach((_pm, i) => {
    const l = String.fromCharCode(72 + i); // char 72 is H
    finalLine.push({ formula: `SUM(${l}2:${l}${lastLine})`, style: { numFmt: '0.00' }  });
  });
  formattedData.push(finalLine);

  return { formattedData, formattedColumns };
};

const baseColumns = [
  {
    title: 'Date',
    dataIndex: 'date',
    sorter: (a, b, sortOrder) => {
      const diff = a.rawDate - b.rawDate;
      if (diff === 0) {
        return sortOrderToN(sortOrder) * a.store.localeCompare(b.store);
      }
      return diff;
    },
    defaultSortOrder: 'ascend',
  },
  {
    title: 'Magasin',
    dataIndex: 'store',
    sorter: (a, b, sortOrder) => {
      const diff = a.store.localeCompare(b.store);
      if (diff === 0) {
        return sortOrderToN(sortOrder) * (a.rawDate - b.rawDate);
      }
      return diff;
    },
  },
  {
    title: 'ID',
    dataIndex: 'storeId',
    sorter: (a, b) => a.storeId.localeCompare(b.storeId),
  },
  {
    title: 'Nombre d’achats',
    dataIndex: 'nbPurchases',
    sorter: (a, b) => a.nbPurchases - b.nbPurchases,
  },
  {
    title: 'Montant total facturé',
    dataIndex: 'chargedPrice',
    sorter: (a, b) => a.chargedPriceCents - b.chargedPriceCents,
  },
  {
    title: 'Montant avant remises',
    dataIndex: 'originalPrice',
    sorter: (a, b) => a.originalPriceCents - b.originalPriceCents,
  },
  {
    title: 'Total des remises',
    dataIndex: 'discountPrice',
    sorter: (a, b) => a.discountPriceCents - b.discountPriceCents,
  },
];

const ExportReport = (props) => {
  const [state, setState] = useState({});
  var blob;

  useEffect(() => {
    const { formattedData, formattedColumns } = formatDataForExcelJS(props.data);

    ExcelUint8Buffer([{ worksheetName: 'Lyf Pay', columns: formattedColumns, data: formattedData}]).then((arrayBufferData) => {
      // eslint-disable-next-line
      blob = new Blob([new Uint8Array(arrayBufferData)]);
      const a = new FileReader();
      a.onload = (e) => {
        setState({ ...state, link: e.target.result });
      };
      a.readAsDataURL(blob);
    });
  }, [props.data.length]);

  const onClick = (e) => {
    // IE hack: https://stackoverflow.com/questions/37203771/download-base64-data-using-javascript-ie11
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveOrOpenBlob(blob, props.filename);
      e.preventDefault();
    }
  };

  if (!state.link) {
    return null;
  }

  return (
    <Button
      type="primary"
      icon={<DownloadOutlined />}
      size="large"
      href={state.link}
      download={props.filename}
      onClick={onClick}
    >
        Télécharger le rapport
    </Button>
  );
};

export default withRouter(({ params }) => {
  const [storePurchases, setStorePurchases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [columns, setColumns] = useState(baseColumns);

  const { minDate, maxDate } = params;

  useEffect(() => {
    getStoresReport(minDate, maxDate).then((data) => {
      setStorePurchases(data);
    }).catch(setError).finally(() => setLoading(false));
  }, [minDate, maxDate]);

  useEffect(() => {
    const paymentMethods = extractPaymentMethods(storePurchases);
    const newColumns = paymentMethods.map(pm => ({
      title: `Payé par ${pm}`,
      dataIndex: `paymentMethods.${pm}`,
    }));
    setColumns([...baseColumns, ...newColumns]);
  }, [storePurchases]);

  const data = storePurchases.map((storePurchase, i) => {
    const o = {
      key: i,
      date: moment(storePurchase.date).format('YYYY-MM-DD'),
      rawDate: moment(storePurchase.date),
      store: storePurchase.store.name,
      storeId: storePurchase.store.external_identifier,
      chargedPrice: (<Price {...storePurchase.charged_price} />),
      originalPrice: (<Price {...storePurchase.original_price} />),
      discountPrice: (<Price {...storePurchase.discount_price} />),
      nbPurchases: storePurchase.nb_purchases,
      paymentMethods: {},
    };

    storePurchase.payment_methods.forEach(paymentMethod => {
      o.paymentMethods[paymentMethod.identifier] = (<Price {...paymentMethod.amount} />);
    });

    return o;
  });

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: '0.75em' }}>
        <ExportReport data={storePurchases} filename={`rapport_lyf_pay_${minDate}_${maxDate}.xlsx`} />
      </div>

      {error && (<Alert message={`Impossible d’accéder au rapport global. Veuillez contacter le support (support@neos.app) en leur communiquant l’erreur (${error.message}).`} type="error" style={{ marginBottom: 16 }} />)}

      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        loading={loading}
        scroll={{ x: true }}
      />
    </div>
  );
});
