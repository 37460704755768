import React, { useEffect, useContext } from 'react';
import { SessionContext } from '../../context/session-context';
import SelectStore from './SelectStore';

const SelectStoreAllUserStores = (props) => {
  const [session] = useContext(SessionContext);
  const stores = session.user ? session.user.all_stores : [];

  useEffect(() => {
    if (stores.length > 0 && props.onChange && !props.multiple) {
      props.onChange(stores[0].id);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <SelectStore {...props} stores={stores} />
  );
};

export default SelectStoreAllUserStores;

// https://github.com/react-component/form/issues/287

export const SelectStoreAllUserStoresComponent = (props) => {
  return (<SelectStoreAllUserStores {...props} />);
};