import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'antd';
import BackofficeUserRole from './BackofficeUserRole';
import SendInviteButton from './ReinviteBackofficeUserButton';

export default ({ user }) => {
  const cardTitle = `${user.given_name} ${user.family_name}`.trim() || 'Utilisateur';
  const isAdmin = user.role === 'admin';
  const nbPartners = user.partners.length;
  const multiplePartners = nbPartners !== 1;
  const multipleStores = user.stores.length !== 1;

  return (
    <Card title={cardTitle} extra={<Link to={`/users/${user.id}/edit`}>Modifier</Link>}>
      <b>Adresse email{'\u00A0'}:</b> {user.email}<br />
      {user.invite_pending && (
        <>
          <b>Invitation acceptée{'\u00A0'}:</b> non <SendInviteButton user={user} /><br />
        </>
      )}
      <b>Utilise NEOS Pro{'\u00A0'}:</b> {user.has_push_tokens ? 'Oui' : 'Non'}<br />
      <b>Rôle{'\u00A0'}:</b> <BackofficeUserRole userRole={user.role} />{isAdmin ? ' (a accès à tout)' : null}
      {!isAdmin && (<>
        <br /><br />
        <b>Enseigne{multiplePartners && 's'} accessible{multiplePartners && 's'}{'\u00A0'}:</b> {nbPartners === 0 ? 'aucune' : user.partners.map(p => p.name).join(',\u00A0')}<br />
        {nbPartners === 1 && (<><i>(Tous les magasins de cette enseigne sont accessibles)</i><br /></>)}
        {nbPartners > 1 && (<><i>(Tous les magasins de ces enseignes sont accessibles)</i><br /></>)}
        <b>Magasin{multipleStores && 's'} supplémentaire{multipleStores && 's'} accessible{multipleStores && 's'}{'\u00A0'}:</b> {user.stores.length === 0 ? 'aucun' : user.stores.map(p => p.name).join(',\u00A0')}
      </>)}
    </Card>
  );
};
