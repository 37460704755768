import React, { Suspense, lazy } from 'react';
import { withRouter } from '../../hooks/withRouter';
const Map = lazy(() => import('../../components/Map/Map'));

export default withRouter(({ location }) => {
  const params = new URLSearchParams(location.search);

  // Auto login
  const token = params.get('token');
  if (token) {
    localStorage.setItem('user.token', token);
  }

  return (
    <Suspense fallback={(<div>Chargement...</div>)}>
      <Map forceLive={params.get('live')} />
    </Suspense>
  );
});
