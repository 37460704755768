import React from 'react';
import { HeartTwoTone, StarTwoTone, ThunderboltTwoTone } from '@ant-design/icons';
import { Table, Tooltip } from 'antd';
import moment from 'moment';
import Price from '../Price/Price';
import { withRouter } from '../../hooks/withRouter';
import PropTypes from 'prop-types';
import ChargeStatus from '../ChargeStatus/ChargeStatus';
import VerificationStatus from '../Verification/VerificationStatus';
import Platform from '../Platform/Platform';
import { shortCalendar } from '../../services/time';
import './PurchasesTable.css';

const PurchaseTable = ({
  purchases,
  storeColumn = true,
  statusColumns = true,
  paymentTypesColumn = false,
  navigate,
  handleTableChange,
  loading,
  idColumn = true,
  maxResults,
  dateCalendar = false,
  size = 'default',
  showHeader = true,
  showLoading = true,
  animate = false,
  ...pagination
}) => {
  const columns = [];
  columns.push({
    title: '',
    dataIndex: 'platform',
    width: 60,
  });
  if (idColumn) {
    columns.push({
      title: 'ID',
      dataIndex: 'short_id',
    });
  }
  columns.push({
    title: 'Date',
    dataIndex: 'created_at',
  });
  if (storeColumn === true) {
    columns.push({
      title: 'Magasin',
      dataIndex: 'store_name',
    });
  }
  if (paymentTypesColumn === true) {
    columns.push({
      title: 'Types de paiement',
      dataIndex: 'payment_types',
    });
  }
  if (statusColumns === true) {
    columns.push({
      title: 'Paiement',
      dataIndex: 'charge_status',
    });
    columns.push({
      title: 'Contrôle',
      dataIndex: 'verification_status',
    });
  }
  columns.push({
    title: 'Prix',
    dataIndex: 'total_price',
    width: 100,
  });

  const data = purchases
    .map((purchase) => {
      let version = null;
      if (purchase.platform_version) {
        if (purchase.platform_version.match(/sdk/)) {
          version = String(purchase.platform_version.split(' ')[0].replace('sdk-', '')).substring(0, 10);
        } else if (purchase.platform_version.match(/mpos/)) {
          version = String(purchase.platform_version.split(' ')[0].replace('mpos-', '')).substring(0, 10);
        } else {
          version = String(purchase.platform_version.split('-')[0]).substring(0, 10);
        }
      }
      purchase.platform_version = '';

      return {
        id: purchase.id,
        key: purchase.id,
        short_id: purchase.short_identifier,
        store_name: purchase.store.name,
        total_price: (<Price {...purchase.total_price} />),
        created_at: dateCalendar ? shortCalendar(purchase.created_at) : moment(purchase.created_at).format('ddd DD/MM [à] HH:mm'),
        charge_status: (<ChargeStatus status={purchase.charge_status} />),
        payment_types: purchase.payment_types,
        verification_status: (<VerificationStatus status={purchase.verification_status} />),
        platform: (<div style={{ textAlign: 'center' }}>
          <Platform platform={purchase.platform} version={purchase.platform_version} />
          {purchase.is_queuebusting && !purchase.platform_version.match(/mpos/) && (
            <Tooltip title="Achat via queuebusting">
              <ThunderboltTwoTone />
            </Tooltip>
          )}
          {!purchase.is_queuebusting && purchase.first_purchase && (
            <Tooltip title="Premier achat de cet utilisateur">
              <StarTwoTone twoToneColor="#52c41a" />
            </Tooltip>
          )}
          {!purchase.is_queuebusting &&!purchase.first_purchase && purchase.first_purchase_in_store && (
            <Tooltip title="Premier achat de cet utilisateur dans ce magasin">
              <HeartTwoTone twoToneColor="#eb2f96" />
            </Tooltip>
          )}
          <div style={{ fontSize: 10, height: 0, width: 30 }}>{version}</div>
        </div>),
      };
    })
    .filter((_purchase, i) => maxResults === undefined || i < maxResults);

  const paginationConfig = {
    current: pagination.current_page,
    pageSize: pagination.per_page,
    total: pagination.total_count,
  };

  return (
    <Table
      dataSource={data}
      columns={columns}
      onRow={(purchase) => {
        return {
          onClick: () => navigate(`/purchases/${purchase.id}`),
          style: {
            cursor: 'pointer',
          },
          className: animate ? 'PurchasesTable-Row-Animated' : '',
        };
      }}
      pagination={pagination.pagination === false ? false : paginationConfig}
      onChange={(newPagination) => handleTableChange(newPagination)}
      loading={showLoading && loading}
      size={size}
      showHeader={showHeader}
    />
  );
};

PurchaseTable.propTypes = {
  storeColumn: PropTypes.bool,
  paymentTypesColumn: PropTypes.bool,
  purchases: PropTypes.arrayOf(PropTypes.shape({
    store: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
  })).isRequired,
  idColumn: PropTypes.bool,
  maxResults: PropTypes.number,
  dateCalendar: PropTypes.bool,
  showHeader: PropTypes.bool,
  showLoading: PropTypes.bool,
  animate: PropTypes.bool,
};

export default withRouter(PurchaseTable);
