import { useState, useEffect } from 'react';
import { getBackofficeUser } from '../services/api';

export default (userId) => {
  const [user, setUser] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    getBackofficeUser(userId).then(setUser).catch(setError);
  }, [userId]);

  return [user, error];
};
