import React from 'react';
import PropTypes from 'prop-types';
import { HeartTwoTone, StarTwoTone, ThunderboltTwoTone } from '@ant-design/icons';
import { Card, Tooltip } from 'antd';
import moment from 'moment';
import Color from 'color';
import ActiveAdminLink from '../ActiveAdmin/ActiveAdminLink';

const Brand = ({ background_color, image, name }) => (
  <div style={{
    height: 'calc(1em + 6px)',
    borderRadius: '4px',
    backgroundColor: background_color,
    display: 'inline-block',
    border: `1px solid ${Color(background_color).darken(0.5)}`,
    overflow: 'hidden',
    verticalAlign: 'middle',
    padding: '2px',
    marginRight: '4px',
  }}>
    <img src={image && image.original.url} style={{ height: '1em', display: 'block' }} alt={name} />
  </div>
);

const LoyaltyInfo = ({ hasLoyalty, isCorporate }) => {
  if (!hasLoyalty) {
    return (
      <Tooltip title="L’utilisateur n’a pas enregistré de carte de fidélité dans ce magasin" placement="bottom">non</Tooltip>
    );
  } else if (hasLoyalty && !isCorporate) {
    return (
      <Tooltip title="L’utilisateur a une carte de fidélité dans ce magasin" placement="bottom">oui</Tooltip>
    );
  } else {
    return (
      <span>oui (employé(e))</span>
    );
  }
};

const UserCard = ({ purchases_count, brands = [], created_at, has_loyalty, is_corporate, first_purchase, first_purchase_in_store, is_queuebusting, withDetails = false, id, ...props }) => (
  <Card title="Utilisateur" extra={withDetails && (<ActiveAdminLink url={`/users/${id}`} />)} {...props}>
    {created_at && purchases_count !== undefined && (
      <>
        <strong>Inscription{'\u00A0'}:</strong> {moment(created_at).calendar()}<br />
        <strong><Tooltip title="toutes enseignes condondues" placement="bottom">Nombre total d’achats{'\u00A0'}:</Tooltip></strong> {purchases_count}<br />
        <strong>Enseignes{'\u00A0'}:</strong> {brands.length === 0 ? 'Aucune' : brands.map((brand) => (<Brand key={brand.id} {...brand} />))}<br />
        <strong>Fidélisé{'\u00A0'}:</strong> <LoyaltyInfo hasLoyalty={has_loyalty} isCorporate={is_corporate} /><br />
        {(first_purchase || first_purchase_in_store || is_queuebusting) && (<br />)}
      </>
    )}
    {is_queuebusting && (
      <div>
        <ThunderboltTwoTone /> Cet achat a été réalisé en queuebusting.
      </div>
    )}
    {first_purchase && (
      <div>
        <StarTwoTone twoToneColor="#52c41a" /> C‘est son premier achat sur Lyf Pay.
      </div>
    )}
    {first_purchase_in_store && (
      <div>
        <HeartTwoTone twoToneColor="#eb2f96" /> C‘est son premier achat dans ce magasin.
      </div>
    )}
  </Card>
);

UserCard.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.object),
  purchases_count: PropTypes.number,
};

export default UserCard;
