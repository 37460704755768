import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import BarChart from '../BarChart/BarChart';
import NoData from '../BarChart/NoData';
import Loading from '../BarChart/Loading';

const NumericChart = ({ data, xKey, series, loading, interval = 1, ...props }) => {
  if (loading) {
    return (<Loading />);
  }
  if (!data || data.length === 0) {
    return (<NoData />);
  }

  const defaultData = {};
  series.forEach((serie) => {
    defaultData[serie.dataKey] = 0;
  });

  const sortedData = _.sortBy(data, xKey);
  const chartData = [];
  sortedData.forEach((data) => {
    if (chartData.length === 0) {
      chartData.push(data);
      return;
    }
    const last = _.last(chartData);
    for (let i = last[xKey] + interval; i < data[xKey]; i += interval) {
      chartData.push({
        [xKey]: i,
        ...defaultData,
      });
    }
    chartData.push(data);
  });

  return (
    <BarChart
      data={chartData}
      xKey={xKey}
      series={series}
      {...props}
    />
  );
};

NumericChart.propTypes = {
  data: PropTypes.array.isRequired,
  series: PropTypes.arrayOf(PropTypes.shape({
    dataKey: PropTypes.string.isRequired,
    label: PropTypes.string,
    reducer: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    stackId: PropTypes.string,
  })).isRequired,
  aggBy: PropTypes.string,
  loading: PropTypes.bool,
};

export default NumericChart;
