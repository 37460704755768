import React, { useContext } from 'react';
import SelectStore from './SelectStore';
import { withRouter } from '../../hooks/withRouter';
import { SessionContext } from '../../context/session-context';

const SelectStores = ({ location, navigate, basePath = '', ...props}) => {
  const [ session ] = useContext(SessionContext);

  const params = new URLSearchParams(location.search);
  const storesIds = params.get('stores') ? params.get('stores').split(/,/g) : [];
  const partnersIds = params.get('partners') ? params.get('partners').split(/,/g) : [];
  delete props.params; // We don't want this property to be passed down
  const partners = session.user.partners.sort((a,b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
  const stores = session.user.all_stores.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  const onChange = (newStoresIds, newPartnersIds, withPartnersIds) => {
    const params = new URLSearchParams(location.search);

    if (newStoresIds.join(',') !== params.get('stores') || (withPartnersIds && newPartnersIds.join(',') !== params.get('partners'))) {
      if (newStoresIds && newStoresIds.length > 0) {
        params.set('stores', newStoresIds.join(','));
      } else {
        params.delete('stores');
      }
      if (withPartnersIds && newPartnersIds && newPartnersIds.length > 0) {
        params.set('partners', newPartnersIds.join(','));
      } else {
        params.delete('partners');
      }

      navigate(`${basePath}?${params.toString()}`);
    }
  };

  return (
    <SelectStore
      selectedStores={storesIds}
      selectedPartners={partnersIds}
      stores={stores}
      partners={partners}
      onChange={onChange}
      multiple
      {...props}
    />
  );
};

export default withRouter(SelectStores);
