import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from '../../hooks/withRouter';
import { SessionContext } from '../../context/session-context';
import { createSession } from '../../services/session';
import { Card, Spin, Input, Button, Alert, Form } from 'antd';
import './Invite.css';
import { getBackofficeUserByResetToken, resetPassword } from '../../services/api';
import { Link } from 'react-router-dom';
import { validatePasswordRules } from '../../utils/PasswordUtils';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const ResetPassword = (props) => {
  const dispatch = useContext(SessionContext)[1];
  const [state, setState] = useState({
    loading: true,
    wrongLink: false,
    success: undefined,
    error: undefined,
    loadingSave: false,
  });

  useEffect(() => {
    getBackofficeUserByResetToken(props.params)
      .then((user) => {
        setState({
          ...state,
          loading: false,
          user: user,
        });
      })
      .catch(() => {
        setState({
          ...state,
          loading: false,
          wrongLink: true,
        });
      });
    // eslint-disable-next-line
  }, []);

  const passwordFieldsNotEmpty = () => {
    const password = state.password;
    const passwordConfirmation = state.passwordConfirmation;
    return password || passwordConfirmation;
  };

  const canSubmitPassword = () => {
    const password = state.password;
    const passwordConfirmation = state.passwordConfirmation;

    return validatePasswordRules(password, passwordConfirmation);
  };

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    setState({
      ...state,
      success: undefined,
      error: undefined,
      loadingSave: true,
    });
    resetPassword({
      password: state.password,
      password_confirmation: state.passwordConfirmation,
      token: props.params.token,
    })
      .then(async () => {
        await createSession({
          email: state.user.email,
          password: state.password,
        }, dispatch);

        setState({
          ...state,
          success: true,
        });
      })
      .catch((error) => {
        if (error.response?.data?.error) {
          setState({
            ...state,
            error: error.response.data.error,
            loadingSave: false,
          });
        } else {
          setState({
            ...state,
            error: 'Impossible de réinitialiser votre mot de passe.',
            loadingSave: false,
          });
        }
      });
  };

  const onValuesChange = ({ password, 'password-confirmation': passwordConfirmation }) => {
    if (password !== undefined)
      setState({...state, password });
    if (passwordConfirmation !== undefined)
      setState({...state, passwordConfirmation });
  };


  const actions = [];
  if (!state.loading && !state.wrongLink) {
    actions.push(
      <Button
        type="primary"
        htmlType="submit"
        key="1"
        disabled={!canSubmitPassword()}
        onClick={onSubmit}
        loading={state.loadingSave}
      >
        Terminer la création de mon compte
      </Button>,
    );
  }

  return (
    <div className="InviteContainer">
      {state.success ? (
        <Alert
          type="success"
          message={
            <span>
              Votre mot de passe a bien été modifié. <Link to="/">Cliquez ici pour accéder au backoffice.</Link>
            </span>
          }
        />
      ) : (
        <Form onFinish={onSubmit} onValuesChange={onValuesChange}>
          <Card title="Lyf Pay | Backoffice" className="InviteForm" actions={actions}>
            {state.loading && (
              <div style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            )}
            {!state.loading && state.wrongLink && (
              <div>
                <Alert
                  message={
                    <span>
                      Le lien est incorrect ou a expiré. Vérifiez celui-ci, ou bien{' '}
                      <Link to="/reset-password">faites une nouvelle demande de mot de passe oublié</Link>.
                    </span>
                  }
                  type="error"
                  showIcon
                />
              </div>
            )}
            {!state.loading && !state.wrongLink && (
              <>
                <p>
                  {state.user.given_name ? (
                    <span>Bonjour {state.user.given_name}, veuillez </span>
                  ) : (
                    'Veuillez '
                  )}
                  indiquer un nouveau mot de passe pour le compte {state.user.email}{'\u00A0'}:
                </p>
                <Form.Item {...formItemLayout} name='password' label="Mot de passe">
                  <Input type="password" placeholder="********" />
                </Form.Item>
                <Form.Item {...formItemLayout} name='password-confirmation' label="Confirmation du mot de passe" style={{ marginBottom: 0 }}>
                  <Input type="password" placeholder="********" />
                </Form.Item>
                {state.error && <Alert message={state.error} type="error" />}
                {passwordFieldsNotEmpty() && !canSubmitPassword() && (
                  <Alert
                    message={
                      <span>
                        Votre mot de passe doit contenir au moins dix caractères dont une majuscule, une minuscule, un
                        chiffre et un caractère spécial
                      </span>
                    }
                    type="info"
                  />
                )}
              </>
            )}
          </Card>
        </Form>
      )}
    </div>
  );
};

export default withRouter(ResetPassword);
