import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { fillMissingDates, aggregateDates, shortDateFormatter, longDateFormatter, sortDates } from '../../lib/timeSeries';
import BarChart from '../BarChart/BarChart';
import NoData from '../BarChart/NoData';
import Loading from '../BarChart/Loading';

const DateChart = ({ data, aggBy = 'day', minDate, maxDate, loading = false, series, ...props }) => {
  if (loading) {
    return (<Loading />);
  }
  if (!data || data.length === 0) {
    return (<NoData />);
  }

  const defaultData = {};
  series.forEach((serie) => {
    defaultData[serie.dataKey] = 0;
  });
  const chartData = sortDates(aggregateDates(fillMissingDates(data, minDate, maxDate, defaultData), series, aggBy));

  return (
    <BarChart
      data={chartData}
      xKey="date"
      series={series}
      shortXFormatter={shortDateFormatter(aggBy)}
      longXFormatter={longDateFormatter(aggBy)}
      {...props}
    />
  );
};

DateChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.instanceOf(moment).isRequired,
  })),
  series: PropTypes.arrayOf(PropTypes.shape({
    dataKey: PropTypes.string.isRequired,
    label: PropTypes.string,
    reducer: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    stackId: PropTypes.string,
  })).isRequired,
  aggBy: PropTypes.string,
  loading: PropTypes.bool,
};

export default DateChart;
