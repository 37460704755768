import React, { useState, useEffect } from 'react';
import SelectStores from '../../components/SelectStore/SelectStoresURLState';
import SelectChargeStatus from '../../components/ChargeStatus/SelectChargeStatus';
import PurchasesTableData from '../../components/PurchasesTable/PurchasesTableData';
import { ReloadOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { withRouter } from '../../hooks/withRouter';
import Responsive from 'react-responsive';
import styled from 'styled-components';
import useConstructor from '../../utils/UseConstructor';

const Search = Input.Search;

const SearchInputs = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const Purchases = (props) => {
  const [state, setState] = useState({});

  useConstructor(() => {
    const params = new URLSearchParams(props.location.search);

    setState({
      version: 1,
      search: params.get('search'),
    });
  });

  const chargeStatusChange = (chargeStatus) => {
    const params = new URLSearchParams(props.location.search);
    if (chargeStatus !== params.get('charge_status')) {
      if (chargeStatus) {
        params.set('charge_status', chargeStatus);
      } else {
        params.delete('charge_status');
      }
      props.navigate(`/purchases/list/?${params.toString()}`);
    }
  };

  const onSearch = (search) => {
    const params = new URLSearchParams(props.location.search);
    if (search !== params.get('search')) {
      if (search) {
        params.set('search', search);
      } else {
        params.delete('search');
      }
      props.navigate(`/purchases/list/?${params.toString()}`);
    }
  };

  const onSearchChange = (e) => {
    setState({ ...state, search: e.target.value });
  };

  const pageChange = (page) => {
    props.navigate(`/purchases/list/${page}${props.location.search}`);
  };

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    setState({ ...state, search: params.get('search') });
    // eslint-disable-next-line
  }, [props.location.search]);

  const params = new URLSearchParams(props.location.search);
  const storesIds = params.get('stores') ? params.get('stores').split(/,/g) : [];
  const partnersIds = params.get('partners') ? params.get('partners').split(/,/g) : [];
  const search = params.get('search');
  const chargeStatus = params.get('charge_status') || '';

  return (
    <div>
      <SearchInputs>
        <div style={{ flexGrow: '1' }}>
          <Responsive minWidth={768}>
            Filtrer par magasin{'\u00A0'}:
            <SelectStores
              style={{ marginLeft: '0.75em', width:'50%' }}
              placeholder="Tous"
              basePath="/purchases/list"
            />
          </Responsive>
          <Responsive maxWidth={767}>
            <SelectStores
              style={{ width: 'calc(100% - 24px - 0.75rem)' }}
              placeholder="Tous les magasins"
              basePath="/purchases/list"
            />
          </Responsive>
          <Button
            shape="circle"
            icon={<ReloadOutlined />}
            type="primary"
            size="small"
            onClick={() => setState({ ...state, version: state.version + 1 })}
            ghost
            style={{ marginLeft: '0.75em' }}
          />
        </div>
        <Responsive minWidth={768}>
          <div>
            <SelectChargeStatus
              allOption
              onChange={chargeStatusChange}
              value={chargeStatus}
            />
          </div>
          <div>
            <Search
              placeholder="Rechercher par ID ou montant"
              onSearch={onSearch}
              style={{ width: 250 }}
              onChange={onSearchChange}
              value={state.search}
            />
          </div>
        </Responsive>
        <Responsive maxWidth={767}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '0.5rem' }}>
            <SelectChargeStatus
              allOption
              onChange={chargeStatusChange}
              value={chargeStatus}
              style={{ width: '60%' }}
            />
            <Search
              placeholder="Rechercher par ID ou montant"
              onSearch={onSearch}
              style={{ width: '35%' }}
              onChange={onSearchChange}
              value={state.search}
            />
          </div>
        </Responsive>

      </SearchInputs>
      <Responsive minWidth={768}>
        {(largeScreen) => (
          <PurchasesTableData
            storesIds={storesIds}
            partnersIds={partnersIds}
            search={search}
            page={parseInt(props.params.page || 1, 10)}
            onPageChange={pageChange}
            version={state.version}
            chargeStatus={chargeStatus}
            display={largeScreen ? 'table' : 'list'}
            loadMore={!largeScreen}
          />
        )}
      </Responsive>

    </div>
  );
};

export default withRouter(Purchases);
