import React from 'react';
import styled from 'styled-components';

import editUser from './images/edit-user.png';
import createUser from './images/create-user.png';

const Illu = styled.img`
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);

  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;
const CenteredIllu = styled(Illu)`
  width: 80%;
  margin-left: 10%;
  margin-top: 2rem;
`;
const FloatingIllu = styled(Illu)`
  width: 50%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  float: ${props => props.right ? 'right' : 'left'};
  margin-${props => props.right ? 'left' : 'right'}: 2rem;

  @media (max-width: 767px) {
    float: none;
  }
`;

export default [
  {
    date: '2019-05-21T14:12Z',
    title: 'Édition des droits',
    content: (
      <p>
        Il est désormais possible pour les responsables magasins de <b>voir et de modifier les droits</b> des autres utilisateurs backoffice.
        Cela se passe depuis la page Utilisateurs dans le menu de gauche, puis Voir ou Modifier sur la ligne d’un utilisateur.<br />
        De plus un tableau détaillant les différents rôles est maintenant présent sur la page de création ou de modification d’un utilisateur, permettant d’avoir une vision claire des permissions de chaque rôle.
        <CenteredIllu src={editUser} />
      </p>
    ),
  },
  {
    date: '2019-04-30T12:00Z',
    title: 'Accès à une enseigne entière',
    content: (
      <p>
        Lors de la création d’un utilisateur, il est maintenant possible de donner accès à tous les magasins d’une enseigne d’un coup.
        Plus besoin de sélectionner un à un les dix magasins de l’enseigne{'\u00A0'}!
        <br /><br />
        <FloatingIllu src={createUser} left />
        <br /><br />
        Ici, l’utilisateur aura accès à tous les magasins Sephora, tous les magasins Bio{'\u00A0'}c’{'\u00A0'}Bon, ainsi qu’à la Grande Épicerie Rive Droite.
      </p>
    ),
  },
];
